import React from 'react'
import _, { findIndex, indexOf } from 'lodash';
import { Doughnut, Line, Bar } from 'react-chartjs-2'
import capacity from '../images/utilization1.png'
import ToolTipInfo from '../components/tooltipComponent';
import { isDesktop, isTablet, isMobile } from 'react-device-detect'

const useImpactMetricsGraphDetails = (utilityDetails, graphType, idToPrint, dataValue, options, contentId) => {
    let itemToPush = false
    let styleProp = {}
    let css = {}
    const [width, setWidth] = React.useState(window.innerWidth);
    if(isDesktop){
        css = { width: '114px', height: '114px' }        
    }else if(isTablet){
        css = { width: '250px', height: '250px' }  
    }else{
        css = { width: '114px', height: '114px' }        
    }
    const removeNaNValue = (graphArr) => {
        // graphArr = [
        //     {
        //         "propName": "Utilization",
        //         "propLable": "Utilization (%)",
        //         "propColor": "#FFD100",
        //         "propTitle": null,
        //         "propSubTitle": null,
        //         propValue: [
        //             "10.0",
        //             "5.0",
        //             "5.0",
        //             "6.0",
        //             "20.0",
        //             "NaN",
        //             "NaN",
        //             "NaN",
        //             "30.0",
        //             "NaN",
        //             "NaN",
        //             "50.0",
        //             "NaN", "0.0", "0.0", "19.8", "0.0", "0.0", "0.0", "0.0", "15.0", "0.0", "0.0", "0.0", "0.0", "0.0", "NaN", "10.0"]
        //     },
        //     {
        //         "propName": "Utilization",
        //         "propLable": "Utilization (%)",
        //         "propColor": "#FFDDDD",
        //         "propTitle": null,
        //         "propSubTitle": null,
        //         propValue: [
        //             "10.0",
        //             "5.0",
        //             "5.0",
        //             "6.0",
        //             "20.0",
        //             "0.0",
        //             "NaN",
        //             "NaN",
        //             "30.0",
        //             "NaN",
        //             "NaN",
        //             "NaN",
        //             "60.0", "0.0", "0.0", "19.8", "0.0", "0.0", "0.0", "0.0", "15.0", "0.0", "0.0", "0.0", "0.0", "0.0", "0.0", "0.0"]
        //     }
        // ] 
        let arrayNaNDictionary = []
        _.map(graphArr, (ele, index) => {
            for (let i = 0; i < ele.propValue.length; i++) {
                if (ele.propValue[i] === "NaN" && ele.propValue[i + 1] === "NaN") {
                    arrayNaNDictionary.push(
                        { mainIndex: index, nanIndex: i, toCheck: "" }
                    )
                }
                if (ele.propValue[i] === "NaN" && ele.propValue[i + 1] !== "NaN") {
                    arrayNaNDictionary.push(
                        { mainIndex: index, nanIndex: i, toCheck: "" }
                    )
                    arrayNaNDictionary.push(
                        { mainIndex: index, nanIndex: i + 1, toCheck: i + 1 }
                    )
                }
            }
        })
        let Count = 0;
        let indexToPush = []
        _.map(graphArr, (ele, index) => {
            _.map(arrayNaNDictionary, (innerEle, innerIndex) => {
                if (index === innerEle.mainIndex) {
                    if (innerEle.toCheck === "") {
                        Count = Count + 1;
                        indexToPush.push({ mainIndex: innerEle.mainIndex, nanIndex: innerEle.nanIndex, data: "" })
                    }
                    if (innerEle.toCheck !== "") {
                        let value = parseFloat(ele.propValue[innerEle.toCheck]) / (Count + 1)
                        if (value.toString() === "NaN") {
                            value = 0
                        }
                        indexToPush.push({ mainIndex: innerEle.mainIndex, nanIndex: innerEle.nanIndex, data: parseFloat(value.toFixed(1)) })
                        Count = 0;
                    }
                }
            })
        })
        let assignValue = ""
        for (let i = indexToPush.length - 1; i >= 0; i--) {
            if (parseFloat(indexToPush[i].data) >= 0) {
                assignValue = indexToPush[i].data
            } else {
                indexToPush[i].data = assignValue
            }
        }
        _.map(graphArr, (ele, index) => {
            _.map(indexToPush, (innerEle, innerIndex) => {
                if (index === innerEle.mainIndex) {
                    ele.propValue[innerEle.nanIndex] = innerEle.data
                }
            })
        })
        return graphArr
    }
    const renderGraph = (eachWidgetDetail, widgetDetailsIndex) => {
        eachWidgetDetail.graphData = removeNaNValue(eachWidgetDetail.graphData)
        let id = contentId + "Graph1234" + widgetDetailsIndex;
        if (eachWidgetDetail.graphData.length > 0) {
            idToPrint.push(id);
        }
        let isStacked = graphType.type === "Bar" ? true : false
        dataValue = {
            labels: [],
            datasets: []
        }
        options = {
            responsive: true,
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'x-axis',
                backgroundColor: "white",
                bodyFontColor: "black",
                borderColor: '#42B4E6',
                borderWidth: 1
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.4,
                    maxBarThickness: 12,
                    gridLines: {
                        display: true,
                        borderDash: [3, 3],
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        labelString: eachWidgetDetail.XUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {

                        padding: 10,
                    }
                }],
                yAxes: [{
                    stacked: isStacked,
                    gridLines: {
                        display: true,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 5,
                        labelString: eachWidgetDetail.YUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        stepSize: 1,
                        padding: 10,
                    }
                }],
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
        dataValue.labels = utilityDetails.periods
        _.map(eachWidgetDetail.graphData, ele => {
            dataValue.datasets.push(
                {
                    label: ele.propLable,
                    backgroundColor: ele.propColor,
                    borderColor: ele.propColor,//'#E6E6E6',
                    data: ele.propValue,
                    fill: false,
                    lineTension: 0.1,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    pointStyle: '',
                }
            )
        })

        if (graphType.type === "Line") {
            return <div>
                <Line key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={114} />
            </div>
        } else {
            return <div>
                <Bar key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={114} /></div>
        }
    }
    const renderTable = (graphData, widgetDetailsIndex) => {
        let id = contentId + "Table1234" + widgetDetailsIndex;
        if (graphData.length > 0) {
            idToPrint.push(id);
        }
        return <div id={id} style={{ display: 'none' }}>
            <table border="1" cellPadding="0" cellSpacing="0" bordercolor="lightgray" style={{ width: '100%', textAlign: "center" }}>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>Period</th>
                        {_.map(graphData, (ele, index) => {
                            return <th key={index} style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>{ele.propLable}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {_.map(utilityDetails.periods, (period, index) => {
                        return <tr key={index}>
                            <td style={{ padding: "5px 5px 0px 5px" }}>{period}</td>
                            {_.map(graphData, (ele, innerIndex) => {
                                return <td key={innerIndex} style={{ padding: "5px 5px 0px 5px" }}>
                                    {ele.propValue[index]}
                                </td>
                            })}
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    }
    const renderEnvGraph = (eachWidgetDetail, widgetDetailsIndex) => {
        eachWidgetDetail.graphData = removeNaNValue(eachWidgetDetail.graphData)
        let id = contentId + "Graph1234" + widgetDetailsIndex;
        if (eachWidgetDetail.graphData.length > 0) {
            idToPrint.push(id);
        }
        let isStacked = graphType.type === "Bar" ? true : false
        dataValue = {
            labels: [],
            datasets: []
        }
        options = {
            responsive: true,
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'x-axis',
                backgroundColor: "white",
                bodyFontColor: "black",
                borderColor: '#42B4E6',
                borderWidth: 1
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.4,
                    maxBarThickness: 12,
                    gridLines: {
                        display: true,
                        borderDash: [3, 3],
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        labelString: eachWidgetDetail.XUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {

                        padding: 10,
                    }
                }],
                yAxes: [{
                    stacked: isStacked,
                    gridLines: {
                        display: true,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 5,
                        labelString: eachWidgetDetail.YUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        stepSize: 1,
                        padding: 10,
                    }
                }],
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
        dataValue.labels = utilityDetails.periods
        _.map(eachWidgetDetail.graphData, ele => {
            dataValue.datasets.push(
                {
                    label: ele.propLable,
                    backgroundColor: ele.propColor,
                    borderColor: ele.propColor,//'#E6E6E6',
                    data: ele.propValue,
                    fill: false,
                    lineTension: 0.1,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    pointStyle: '',
                }
            )
        })

        if (graphType.type === "Line") {
            return <div>
                <Line key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={140} />
            </div>
        } else {
            return <div>
                <Bar key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={140} /></div>
        }
    }
    const renderGraphDetails = () => {
        return _.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
            return <div>
            <se-block-content key={widgetDetailsIndex}>
                <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-9 col-lg-9 col-xl-8 text-center">
                        {contentId === "Environment Contribution" ?
                            renderEnvGraph(eachWidgetDetail, widgetDetailsIndex)
                            :
                            renderGraph(eachWidgetDetail, widgetDetailsIndex)
                        }
                        {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-4 text-left">
                        {_.map(eachWidgetDetail.graphData, (graphData, index) => {
                            return <div key={index} className={"row blockDetailsDiv"}>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-size-18">
                                    <div><span class="font-weight-600" align="left">{graphData.propTitle} </span></div>
                                    <div className={graphData.propSubTitle ? "commonDash mt-3" : "commonDash2"} style={{ border: '0.99px solid ' + graphData.propColor }} align="left"></div>
                                    <span style={{ marginLeft: "20px" }}>{graphData.propSubTitle}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </se-block-content>
            </div>
        })
    }
    //Water Utilization metrics &
    const renderWGraph = (eachWidgetDetail, widgetDetailsIndex) => {
        eachWidgetDetail.graphData = removeNaNValue(eachWidgetDetail.graphData)
        let id = contentId + "Graph1234" + widgetDetailsIndex;
        if (eachWidgetDetail.graphData.length > 0) {
            idToPrint.push(id);
        }
        let isStacked = graphType.type === "Bar" ? true : false
        dataValue = {
            labels: [],
            datasets: []
        }
        options = {
            responsive: true,
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'x-axis',
                backgroundColor: "white",
                bodyFontColor: "black",
                borderColor: '#42B4E6',
                borderWidth: 1
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.4,
                    maxBarThickness: 12,
                    gridLines: {
                        display: true,
                        borderDash: [3, 3],
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        labelString: eachWidgetDetail.XUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {

                        padding: 10,
                    }
                }],
                yAxes: [{
                    stacked: isStacked,
                    alignToPixels: false,
                    gridLines: {
                        display: true,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 5,
                        labelString: eachWidgetDetail.YUnits,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        stepSize: 1,
                        padding: 10,
                        crossAlign: 'start',
                    },
                    // afterSetDimensions: (scale) => {
                    //     scale.maxWidth = 100;
                    // }
                    afterSetDimensions: function(axes) {
                        axes.width = 165;
                        axes.maxWidth = 165;
                }
                }],
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
        dataValue.labels = utilityDetails.periods
        _.map(eachWidgetDetail.graphData, ele => {
            dataValue.datasets.push(
                {
                    label: ele.propLable,
                    backgroundColor: ele.propColor,
                    borderColor: ele.propColor,//'#E6E6E6',
                    data: ele.propValue,
                    fill: false,
                    lineTension: 0.1,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    pointStyle: '',
                }
            )
        })
        let hig=sessionStorage.getItem('customerType') === "Agro"?120:90;
        //set dynamic graph rendering
        if(width <= 820){
            hig = 220
        }        
        if (graphType.type === "Line") {
            return <div>
                <Line key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={hig} />
            </div>
        } else {
            return <div>
                <Bar key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={hig} /></div>
        }
    }
    const renderwaterGraphDetails = () => {
        return _.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
            return <se-block-content key={widgetDetailsIndex}>
                <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9 col-xl-8 text-center">
                        {renderWGraph(eachWidgetDetail, widgetDetailsIndex)}
                        {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-4 text-left">
                        {_.map(eachWidgetDetail.graphData, (graphData, index) => {
                            return <div key={index} className={"row blockDetailsDiv"}>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-size-18">
                                    <div><span class="font-weight-600" align="left">{graphData.propTitle} </span></div>
                                    <div className={graphData.propSubTitle ? "commonDash mt-3" : "commonDash2"} style={{ border: '0.99px solid ' + graphData.propColor }} align="left"></div>
                                    <span style={{ marginLeft: "20px" }}>{graphData.propSubTitle}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </se-block-content>
        })
    }

    //installation
    const renderMainGraphDetails = () => {
        let idInfo = contentId + "Graph1234"
        dataValue = {
            labels: [],
            datasets: []
        }
        options = {
            responsive: true,
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'x-axis',
                backgroundColor: "white",
                bodyFontColor: "black",
                borderColor: '#42B4E6',
                borderWidth: 1
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.3,
                    gridLines: {
                        display: true,
                        borderDash: [3, 3],
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        labelString: null,
                        fontSize: 14,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        padding: 10,
                    }
                }],
                yAxes: [],
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        };
        dataValue.labels = utilityDetails.periods
        _.map(utilityDetails.widgetDetails, (widgetDetail, index) => {
            options.scales.xAxes[0].scaleLabel.labelString = widgetDetail.XUnits
            widgetDetail.graphData = removeNaNValue(widgetDetail.graphData)
            _.map(widgetDetail.graphData, eachgraphData => {
                if (index <= 1) {
                    if (widgetDetail.graphData) {
                        itemToPush = true
                    }
                    options.scales.yAxes.push({
                        stacked: true,
                        id: index,
                        position: index === 0 ? 'left' : 'right',
                        gridLines: {
                            display: true,
                            drawTicks: false,
                        },
                        scaleLabel: {
                            display: true,
                            padding: 0,
                            labelString: widgetDetail.YUnits,
                            fontSize: 14,
                            fontweight: "bolder",
                            fontColor: '#272727',
                        },
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            padding: 10,
                        }
                    })
                    dataValue.datasets.push(
                        {
                            yAxisID: index,
                            label: eachgraphData.propLable,
                            backgroundColor: eachgraphData.propColor,
                            borderColor: eachgraphData.propColor,//'#E6E6E6',
                            data: eachgraphData.propValue,
                            fill: false,
                            lineTension: 0.1,
                            borderWidth: 1,
                            pointRadius: 1,
                            pointHoverRadius: 2,
                            pointStyle: '',
                        }
                    )

                }
            })
        })
        if (itemToPush) { idToPrint.push(idInfo) }
        if (graphType.type === "Line") {
            return <div>
                <Line key={Math.random()} redraw={true} id={idInfo}
                    data={dataValue} options={options} height={css.height} />
            </div>
        } else {
            return <div>
                <Bar key={Math.random()} redraw={true} id={idInfo}
                    data={dataValue} options={options} height={css.height} /></div>
        }
    }
    const renderGraphDetails2 = () => {
        return _.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
            if (widgetDetailsIndex === 2) {
                return <se-block-content key={widgetDetailsIndex}>
                    <div class="row">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9 col-xl-8 text-center">
                            {renderGraph(eachWidgetDetail, widgetDetailsIndex)}
                            {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-4 text-left">
                            {_.map(eachWidgetDetail.graphData, (graphData, index) => {
                                return <div key={index} className={"row blockDetailsDiv"}>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-size-18">
                                        <div><span class="font-weight-600" align="left">{graphData.propTitle} </span></div>
                                        <div className={graphData.propSubTitle ? "commonDash mt-3" : "commonDash2"} style={{ border: '0.99px solid ' + graphData.propColor }} align="left"></div>
                                        <span style={{ marginLeft: "20px" }}>{graphData.propSubTitle}</span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </se-block-content>
            }

        })
    }
    const renderGraphDetails1 = () => {
        return <se-block-content>
            <div class="row">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9 col-xl-8 text-center">
                    {renderMainGraphDetails()}
                    {renderTable1()}
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-4 text-left">
                    {_.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
                        if (widgetDetailsIndex <= 1) {
                            return _.map(eachWidgetDetail.graphData, (graphData, index) => {
                                return <div key={index} className={"row blockDetailsDiv"}>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-size-18">
                                        <div><span class="font-weight-600" align="left">{graphData.propTitle} </span></div>
                                        <div className={graphData.propSubTitle ? "commonDash mt-3" : "commonDash2"} style={{ border: '0.99px solid ' + graphData.propColor }} align="left"></div>
                                        <span style={{ marginLeft: "20px" }}>{graphData.propSubTitle}</span>
                                    </div>
                                </div>
                            })
                        }
                    })
                    }
                </div>
            </div>
        </se-block-content>
    }
    const renderTable1 = () => {
        let id = contentId + "Table1234"
        if (itemToPush) { idToPrint.push(id) }
        return <div id={id} style={{ display: 'none' }}>
            <table border="1" cellPadding="0" cellSpacing="0" bordercolor="lightgray" style={{ width: '100%', textAlign: "center" }}>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>Period</th>
                        {_.map(utilityDetails.widgetDetails, (widgetDetail, widgetDetailsIndex) => {
                            if (widgetDetailsIndex <= 1) {
                                return _.map(widgetDetail.graphData, (ele, index) => {
                                    return <th key={index} style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>{ele.propLable}</th>
                                })
                            }
                        })
                        }
                    </tr>
                </thead>
                <tbody>
                    {_.map(utilityDetails.periods, (period, index) => {
                        return <tr key={index}>
                            <td style={{ padding: "5px 5px 0px 5px" }}>{period}</td>
                            {_.map(utilityDetails.widgetDetails, (widgetDetail, widgetDetailsIndex) => {
                                if (widgetDetailsIndex <= 1) {
                                    return _.map(widgetDetail.graphData, (ele, innerIndex) => {
                                        return <td key={innerIndex} style={{ padding: "5px 5px 0px 5px" }}>
                                            {ele.propValue[index]}
                                        </td>
                                    })
                                }
                            })}
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    }
    const renderInfoTable = () => {
        let id = contentId + "InfoTablePng1234";
        idToPrint.push(id);
        return getInfoTable(utilityDetails.tableData, id)
    }
    const getInfoTable = (data, id) => {
        return _.map(data, ele => {
            if(!isMobile){
            return <div class="col-md-12" id={id}>
                <table style={{ width: "95%" }}>
                    <caption style={{ backgroundColor: 'rgb(159, 160, 164)', color: 'white', captionSide: 'top', padding: '10px' }}>
                        {ele.tableName}</caption>
                    <thead>
                        <tr>
                            {_.map(ele.tableValue, tableValue => {
                                return <th style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px' }}>{tableValue.propName}</th>
                            })}

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {_.map(ele.tableValue, tableValue => {
                                return <td style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px' }}>{tableValue.propValue}</td>
                            })}
                        </tr>
                        {/* <tr>
                            {_.map(ele.tableValue, tableValue => {
                                return <td style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px', visibility: 'hidden' }}>dummy</td>
                            })}
                        </tr> */}

                    </tbody>
                </table>
            </div>
            }
            else{
                return (
                    <div class="col-md-12" id={id}>
                        <table style={{ width: "95%" }}>
                            <caption style={{ backgroundColor: 'rgb(159, 160, 164)', color: 'white', captionSide: 'top', padding: '10px' }}>
                                {ele.tableName}
                            </caption>
                            <tbody>
                                {ele.tableValue.map(tableValue => (
                                    <tr>
                                        <th style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px' }}>{tableValue.propName}</th>
                                        <td style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px' }}>{tableValue.propValue}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ); 
            }
        })
    }
    const renderMotorInfoTable = () => {
        let id = contentId + "MotorInfoTablePng1234";
        idToPrint.push(id);
        return getInfoTable(utilityDetails.motorTableData, id)
    }
    //end installation

    //capacity Utilization
    const renderCapacityGraphDetails = () => {
        return _.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
            let id = "summary1234" + widgetDetailsIndex;
            idToPrint.push(id);
            
            return <div>{widgetDetailsIndex === 0 && utilityDetails.widgetDetails.length>1 ? 
                <div class="rows" key={widgetDetailsIndex} >
            <div class="col-md-4 mt-4" id={id}>
                <div class="font-size-22">{utilityDetails.summaryData[widgetDetailsIndex].propName}</div>
                {utilityDetails.summaryData[widgetDetailsIndex].propName === "Capacity Utilization" ? <div>
                    <img src={capacity} height="64px"></img>
                    <span class="font-size-37">{utilityDetails.summaryData[widgetDetailsIndex].propVal}
                        &nbsp;{parseFloat(utilityDetails.summaryData[widgetDetailsIndex].propVal) > 100 ?
                            <ToolTipInfo styleProp={styleProp} />
                            : utilityDetails.summaryData[widgetDetailsIndex].units}
                    </span></div> : null}
                
            </div>
            <div class="text-center col-md-7 mt-4 pl-0">
                {renderGraph(eachWidgetDetail, widgetDetailsIndex)}
                {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
            </div>
        </div>
            :
            widgetDetailsIndex === 1 ? <div class="rows" key={widgetDetailsIndex} >
            <div class="col-md-4 mt-4" id={id}>
                <div class="font-size-22">{utilityDetails.summaryData[widgetDetailsIndex].propName}</div>
                {utilityDetails.summaryData[widgetDetailsIndex].propName === "Capacity Utilization" ? <div>
                    <img src={capacity} height="64px"></img>
                    <span class="font-size-37">{utilityDetails.summaryData[widgetDetailsIndex].propVal}
                        &nbsp;{parseFloat(utilityDetails.summaryData[widgetDetailsIndex].propVal) > 100 ?
                            <ToolTipInfo styleProp={styleProp} />
                            : utilityDetails.summaryData[widgetDetailsIndex].units}
                    </span></div> : null}
                <div> {renderProgressBar()}</div>
            </div>
            <div class="text-center col-md-7 mt-4 pl-0">
                {renderGraph(eachWidgetDetail, widgetDetailsIndex)}
                {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
            </div>
        </div>
                : <div key={widgetDetailsIndex} >
                    <div class="row justify-content-center"><div class="col-md-8" id={id}>
                        <div class="font-size-22">{utilityDetails.summaryData[widgetDetailsIndex].propName}</div>
                        {utilityDetails.summaryData[widgetDetailsIndex].propName === "Capacity Utilization" ? <div>
                            <img src={capacity} height="64px"></img>
                            <span class="font-size-37">{utilityDetails.summaryData[widgetDetailsIndex].propVal}
                                &nbsp;{parseFloat(utilityDetails.summaryData[widgetDetailsIndex].propVal) > 100 ?
                                    <ToolTipInfo styleProp={styleProp} />
                                    : utilityDetails.summaryData[widgetDetailsIndex].units}
                            </span></div> : null}
                    </div></div>
                    <div class="text-center col-md-12 col-xl-8 mt-4">
                        {renderEnvGraph(eachWidgetDetail, widgetDetailsIndex)}
                        {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                    </div>
                </div>
            }
            </div>
        })
    }
    const renderProgressBar = () => {
        return _.map(utilityDetails.widgetData, (widgetData) => {
            return <div class="row align-items-center progress-group-header">
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 px-0 pl-2">
                    <div class="font-size-10">{widgetData.propName}</div>
                    <div class="progress progressBar">
                        <div class="progress-bar"
                            role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ 'width': parseFloat(widgetData.propVal) + '%', backgroundColor: widgetData.propColor }} />
                    </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 px-0 pl-2 barPadding">
                    {widgetData.propVal}%
                </div>
            </div>
        })
    }
    //end capacity Utilization

    const renderTariffTable = (graphData, widgetDetailsIndex) => {  
        let tariffArr = []; let dateArr = [];
        if(utilityDetails.tableData !== undefined && utilityDetails.tableData.length > 0){
            tariffArr = utilityDetails.tableData[0].tableValue[0].propValue;
            dateArr = utilityDetails.tableData[0].tableValue[1].propValue;
        }            
        
        let id = contentId + "Table1234" + widgetDetailsIndex;
        return _.map(utilityDetails.tableData, ele => {            
            return <div class="col-md-12" id={id}>            
            <table id={id} style={{ width: "95%" }}>
                    <caption style={{ backgroundColor: 'rgb(159, 160, 164)', color: 'white', captionSide: 'top', padding: '10px' }}>
                        {ele.tableName}</caption>
                    <thead>
                        <tr>
                            {_.map(ele.tableValue, tableValue => {
                                return <th style={{ overflow: 'hidden', minHeight: '47px', height: 'auto', margin: '0px', padding: '10px' }}>{tableValue.propName}</th>
                            })}

                        </tr>
                    </thead>
                    <tbody>        

                        {_.map(tariffArr, (tableValue, index) => {
                            return <tr>
                                        <td  style={{ padding: "5px 5px 0px 5px" }}>{tariffArr[index]}</td>
                                        <td  style={{ padding: "5px 5px 0px 5px" }}>{dateArr[index]}</td>
                                    </tr>
                            })}

                    </tbody>
                </table>
            </div> 
        });
    }

    //income generation
    const renderIncomeTable = (graphData, widgetDetailsIndex) => {
        if (widgetDetailsIndex) {
            let id = contentId + "Table1234"
            if (graphData.length > 0) {
                idToPrint.push(id);
            }
            return <div id={id} style={{ display: 'none' }}>
                <table border="1" cellPadding="0" cellSpacing="0" bordercolor="lightgray" style={{ width: '100%', textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>Period</th>
                            {_.map(graphData, (ele, index) => {
                                return <th key={index} style={{ backgroundColor: "#4CAF50", color: 'white', padding: "5px 5px 0px 5px" }}>{ele.propLable}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(utilityDetails.periods, (period, index) => {
                            return <tr key={index}>
                                <td style={{ padding: "5px 5px 0px 5px" }}>{period}</td>
                                {_.map(graphData, (ele, innerIndex) => {
                                    return <td key={innerIndex} style={{ padding: "5px 5px 0px 5px" }}>
                                        {ele.propValue[index]}
                                    </td>
                                })}
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        }
    }
    const renderIncomeGraph = (eachWidgetDetail, widgetDetailsIndex) => {
        let id = contentId + "Graph1234" + widgetDetailsIndex;
        eachWidgetDetail.graphData = removeNaNValue(eachWidgetDetail.graphData)
        if (eachWidgetDetail.graphData.length > 0) {
            idToPrint.push(id);
        }
        let isStacked = graphType.type === "Bar" ? true : false
        if (widgetDetailsIndex === 0) {
            dataValue = {
                labels: [],
                datasets: [
                    {
                        label: null,
                        backgroundColor: [],
                        borderColor: [],
                        hoverBackgroundColor: [],
                        hoverBorderColor: [],
                        data: []
                    }]
            }
            options = {
                cutoutPercentage: 85,
                responsive: false,
                title: {
                    display: true,
                    verticalAlign: "right",
                    text: "Source of Income",
                    fontColor: "#333333",
                    fontSize: 22,
                    padding: 10,
                },
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: true,
                    onClick: (e) => e.stopPropagation(),
                    position: 'right',
                    labels: {
                        padding: 10,
                        boxWidth: 15,
                        fontColor: "#333333",
                        fontSize: 15
                    }

                }
            }
        } else {
            dataValue = {
                labels: [],
                datasets: []
            }
            dataValue.labels = utilityDetails.periods
            options = {
                responsive: true,
                animation: {
                    duration: 10,
                },
                tooltips: {
                    mode: 'x-axis',
                    backgroundColor: "white",
                    bodyFontColor: "black",
                    borderColor: '#42B4E6',
                    borderWidth: 1
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        barPercentage: 0.3,
                        maxBarThickness: 12,
                        gridLines: {
                            display: true,
                            borderDash: [3, 3],
                            drawTicks: false,
                        },
                        scaleLabel: {
                            display: true,
                            padding: 10,
                            labelString: eachWidgetDetail.XUnits,
                            fontSize: 14,
                            fontweight: "bolder",
                            fontColor: '#272727',
                        },
                        ticks: {

                            padding: 10,
                        }
                    }],
                    yAxes: [{
                        stacked: isStacked,
                        gridLines: {
                            display: true,
                            drawTicks: false,
                        },
                        scaleLabel: {
                            display: true,
                            padding: 20,
                            labelString: eachWidgetDetail.YUnits,
                            fontSize: 14,
                            fontweight: "bolder",
                            fontColor: '#272727',
                        },
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            stepSize: 1,
                            padding: 10,
                        }
                    }],
                },
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        }
        _.map(eachWidgetDetail.graphData, ele => {
            if (widgetDetailsIndex === 0) {
                dataValue.labels.push(ele.propLable)
                dataValue.datasets[0].label = ""
                dataValue.datasets[0].backgroundColor.push(ele.propColor)
                dataValue.datasets[0].borderColor.push(ele.propColor) //'#E6E6E6')
                dataValue.datasets[0].hoverBackgroundColor.push(ele.propColor)
                dataValue.datasets[0].hoverBorderColor.push(ele.propColor)
                dataValue.datasets[0].data.push(ele.propValue[0])
            } else {
                dataValue.datasets.push(
                    {
                        label: ele.propLable,
                        backgroundColor: ele.propColor,
                        borderColor: ele.propColor,
                        data: ele.propValue,
                        fill: false,
                        lineTension: 0.1,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 2,
                        pointStyle: '',
                    }
                )
            }

        })
        if (widgetDetailsIndex === 1) {
            if (graphType.type === "Line") {
                return <div>
                    <Line key={Math.random()} redraw={true} id={id}
                        data={dataValue} options={options} height={124} />
                </div>
            } else {
                return <div>
                    <Bar key={Math.random()} redraw={true} id={id}
                        data={dataValue} options={options} height={124} /></div>
            }
        } else {
            return <div>  <Doughnut key={Math.random()} redraw={true} id={id}
                data={dataValue} options={options} width="390" height="175" /></div>
        }

    }
    const renderIncomeGraphDetails = () => {
        return _.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
            return <se-block-content key={widgetDetailsIndex}>
                <div class="row mt-30px">
                    <div class="col-xs-8 col-sm-8 col-md-12 col-lg-9 col-xl-8 text-center">
                        {renderIncomeGraph(eachWidgetDetail, widgetDetailsIndex)}
                        {renderIncomeTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                    </div>

                </div>
            </se-block-content>
        })
    }
    //end income generation

    //realtime monitoring details
    const renderRealtimeGraph = (eachWidgetDetail, widgetDetailsIndex) => {
        let id = contentId + "Graph1234" + widgetDetailsIndex;
        if (eachWidgetDetail.graphData.length > 0) {
            idToPrint.push(id);
        }
        let isStacked = graphType.type === "Bar" ? true : false
        dataValue = {
            labels: [],
            datasets: []
        }
        options = {
            responsive: true,
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'x-axis',
                backgroundColor: "white",
                bodyFontColor: "black",
                borderColor: '#42B4E6',
                borderWidth: 1,
                bodyFontSize: 10,
                titleFontSize: 9,
                bodySpacing: 0,
                titleSpacing: 0,
                xPadding: 2,
                yPadding: 2,
                cornerRadius: 5,
                titleMarginBottom: 2,
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barPercentage: 0.3,
                    gridLines: {
                        display: true,
                        borderDash: [3, 3],
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 10,
                        labelString: eachWidgetDetail.XUnits,
                        fontSize: 10,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        fontSize: (window.innerWidth <= 1024) ? 7.2 : 10,
                        padding: 10,
                    }
                }],
                yAxes: [{
                    stacked: isStacked,
                    gridLines: {
                        display: false,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        padding: 20,
                        labelString: eachWidgetDetail.YUnits,
                        fontSize: 10,
                        fontweight: "bolder",
                        fontColor: '#272727',
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 5,
                        fontSize: 10,
                    }
                }],
            },
            legend: {
                display: false,
                position: 'bottom'
            }
        }
        dataValue.labels = utilityDetails.periods
        eachWidgetDetail.graphData = removeNaNValue(eachWidgetDetail.graphData)
        _.map(eachWidgetDetail.graphData, ele => {
            dataValue.datasets.push(
                {
                    label: ele.propLable,
                    backgroundColor: ele.propColor,
                    borderColor: ele.propColor,
                    data: ele.propValue,
                    fill: false,
                    lineTension: 0.1,
                    borderWidth: 1,
                    pointRadius: 1,
                    pointHoverRadius: 2,
                    pointStyle: '',
                }
            )
        })

        if (graphType.type === "Line") {
            return <div>
                <Line key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={90} />
            </div>
        } else {
            return <div>
                <Bar key={Math.random()} redraw={true} id={id}
                    data={dataValue} options={options} height={90} /></div>
        }
    }
    const renderGraphData = (from, to) => {
        let graphNotAvailable = 0
        return <div class="col-md-12 col-xl-5 px-0">
            {_.map(utilityDetails.widgetDetails, (eachWidgetDetail, widgetDetailsIndex) => {
                utilityDetails.widgetDetails.length === 4 ? graphNotAvailable = 1 : graphNotAvailable = 0
                if (widgetDetailsIndex >= from && widgetDetailsIndex <= to) {
                    return <div key={widgetDetailsIndex}>
                        {renderRealtimeGraph(eachWidgetDetail, widgetDetailsIndex)}
                        {renderTable(eachWidgetDetail.graphData, widgetDetailsIndex)}
                    </div>
                }
            })}
            {graphNotAvailable && to === 4 ? _.map(utilityDetails.widgetDetails[0].graphData, graphData => {
                return <div className={"col-md-12 realtime-widget"} style={{paddingLeft:"20%"}}>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-size-18">
                        <div><span class="commonDash" style={{ border: '0.99px solid ' + graphData.propColor }} align="left"></span>
                            <span style={{ marginLeft: "50px" }} class="font-weight-600" align="left">{graphData.propTitle} </span></div>
                    </div>
                </div>
            }) : null}
        </div>

    }
    const renderRealtimeGraphDetails = () => {
        return <div class="rows margintop60">
            {renderGraphData(0, 2)}
            {renderGraphData(3, 5)}
        </div>
    }
    const renderRealtimeInfoTable = () => {
        let id = contentId + "InfoTablePng1234"
        idToPrint.push(id);
        {
            return <div class="rows" id={id}>
                {_.map(utilityDetails.tableData, tableData => {
                    return <div className={sessionStorage.getItem("siteName") !== "" ? "col-md-12" : "col-md-12"} style={{ paddingRight: '0' }}>
                        <table style={{ width: '100%',marginBottom:'10px' }}>
                            <caption style={{ backgroundColor: 'rgb(159, 160, 164)', color: 'white', captionSide: 'top', padding: '5px' }}>
                                {tableData.tableName}</caption>
                            <thead>
                                <tr>
                                    {_.map(tableData.tableValue, tableValue => {
                                        return tableValue.propName!==""? <th class="realTimeEnergyTable">{tableValue.propName}</th>
                                        :null
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(tableData.tableValue, (tableValue, tableIndex) => {
                                        return _.map(tableValue.propValue, (propValue, propIndex) => {
                                            if (tableIndex === 1) {
                                                return <tr>
                                                    {_.map(tableData.tableValue, (tableValue2, tableIndex2) => {
                                                        return tableValue2.propName!==""? <td class="realTimeEnergyTable">{tableData.tableValue[tableIndex2].propValue[propIndex]}</td>
                                                        :null
                                                    })}
                                                </tr>
                                            }

                                        })
                                    })
                                }
                                {/* <tr class="dummyCss">
                                    {_.map(tableData.tableValue, (tableValue2, tableIndex2) => {
                                        return <td class="realTimeEnergyTable dummyCss">dummy</td>
                                    })}
                                </tr> */}
                            </tbody>
                        </table>
                    </div>

                })}
            </div>
        }
    }
    //end realtime monitoring details

    return {
        renderGraphDetails,
        renderInfoTable,
        renderMotorInfoTable,
        renderGraphDetails1,
        renderGraphDetails2,
        renderCapacityGraphDetails,
        renderIncomeGraphDetails,
        renderRealtimeGraphDetails,
        renderRealtimeInfoTable,
        renderwaterGraphDetails,
        renderTariffTable
    }
}
export default useImpactMetricsGraphDetails