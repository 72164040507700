const NAVHEADERS = [
  { id: 1, name: "Start/Stop" },
  { id: 2, name: "Device Settings" },
  { id: 3, name: "Flow Estimation" },
  { id: 4, name: "Reset" },
  { id: 5, name: "TransactionLog" }
];

const NAV3HEADERS = [
  { id: 1, name: "Start/Stop" },
  { id: 2, name: "Device Settings" },
  { id: 4, name: "Reset" },
  { id: 5, name: "TransactionLog" }
];

const NAV2HEADERS = [
  { id: 1, name: "Start/Stop" },
  { id: 5, name: "TransactionLog" }
];

const OptionValues = [
  { id: 1, name: "Standard" },
  { id: 2, name: "SensorlessFluxVectorV" },
  { id: 3, name: "StandardMotorLaw" },
  { id: 4, name: "FivePointVoltageFrequencey" },
  { id: 5, name: "SynchronousMotor" },
  { id: 6, name: "VFQuadratic" },
  { id: 7, name: "EnergySaving" },
];

const RESETLABELS = [
  { id: "1", label: "Reset Energy", name: "resetEnergy" },
  { id: "2", label: "Motor Run Time", name: "motorRunTime" },
  { id: "3", label: "Drive Power On Time", name: "drivePower" },
];

const CONFIGURATIONS = {
  Select_Reference_Channel: "Select Reference Channel",
  Select: "Select",
  Update: "Update",
  Modbus: "Modbus",
  Digital_Input: "Digital Input",
  Last_Refresh: "Last Refresh",
  Update: "Update",
  StartStop: "Motor Start/Stop",
  Adjust_Drive_Frequency: "Adjust Drive Frequency",
  Standard: "Standard",
  Country: "Country",
  India: "+",
  Submit: "Submit",
  Cancel: "Cancel",
  Get: "Get",
  Set: "Set",  
  Dialog_Confirm: "Please Confirm",
  Dialog_Start_Content: "Do you want to Start the motor?",
  Dialog_Stop_Content: "Do you want to Stop the motor?",
  Drive_Frequency: "Drive Frequency (Hz)",
  Nominal_Motor_Power: "Nominal Motor Power (kW)",
  Nominal_Motor_Voltage: "Nominal Motor Voltage (V)",
  Nominal_Motor_Current: "Nominal Motor Current (A)",
  Nominal_Motor_Frequency: "Nominal Motor Frequency (Hz)",
  Nominal_Motor_Speed: "Nominal Motor Speed (RPM)",
  MotorParameterChoice: "Motor Parameter Choice",
  NominalMotorCos: "Nominal Motor cos",
  AutomaticTuning: "Automatic Tuning",
  TuningStatus: "Tuning Status",
  TuningSelection: "Tuning Selection",
  DryRunDetectDelay: "Dry Run Detect Delay (s)",
  DryRunUpperThreshold: "Dry Run Upper Threshold (%)",
  DryRunLowerThreshold: "Dry Run Lower Threshold (%)",
  DryRunFreqThreshold: "Dry Run Freq. Threshold (Hz)",
  Confirm: "Confirm",
  Motor_Control_Type: "Motor Control Type",
  Frequency_Inprogress_Msg: "Frequency Transaction InProgress",
  Motor_Inprogress_Msg: "Motor Transaction InProgress",
  Mobile_Inprogress_Msg: "MobileNo Transaction InProgress",
  Password_Inprogress_Msg: "Password Transaction InProgress",
  MCT_Inprogress_Msg: "Motor Control Type Transaction InProgress",
  Reset_Energy: "Reset Energy",
  Reset_Run_Time: "Reset Run Time",
  VC: "Volume Counter",
  DriveSoftRstrt: "Drive Restart",
  Reset_Operating_Time: "Reset Operating Time",
  Sensorless_Flux: "VVC",
  Standard_MotorLaw: "STD",
  FivePoint_VoltageFrequencey: "UF5",
  Synchronous_Motor: "SYN",
  VF_Quadratic: "UFQ",
  Energy_Saving: "NLD",
  UpdateMotorControlType: "UpdateMotorControlType",
  UpdateReferenceChannel: "UpdateReferenceChannel",
  UpdateFrequency: "UpdateFrequency",
  Reset: "Reset",
  MobileNumber: "MobileNumber",
  StartOrStop: "StartOrStop",
  SetPassword: "SetPassword",
  GetData: "GetData",
  GetLatestPacket: "GetLatestPacket",
  GetTransaction: "GetTransaction",
  Flow_Estimation_Method: "Flow Estimation Method",
  PVCS: "Pumped Volume Counter Source",
  NO: "Flow Meter",
  PQ: "Sensorless",
  SLFV: "SLFV",
  SPUC: "SPUC",
  PumpSpeed: "Pump Speed",
  FlowRate1: "Flow Rate 1 (m3/hr)",
  FlowRate2: "Flow Rate 2 (m3/hr)",
  FlowRate3: "Flow Rate 3 (m3/hr)",
  FlowRate4: "Flow Rate 4 (m3/hr)",
  FlowRate5: "Flow Rate 5 (m3/hr)",
  Power1: "Power 1 (kW)",
  Power2: "Power 2 (kW)",
  Power3: "Power 3 (kW)",
  Power4: "Power 4 (kW)",
  Power5: "Power 5 (kW)",
  FlowRate: "FlowRate",
  SPML: "Multiplier",
  SPDV: "Divider",
  PVLD: "Daily Pumped Volume (m3)",
  PVLT: "Total Pumped Volume (dam3)",
  SLFV_Text: "Instantaneous Flow (m3/hr)",
  SPUC_Text: "Slow Pulse Counter",
  GPRS: "Posting Interval (min)"
};

const DIALOGCONTENT = {
  APH: "Reset energy",
  RTH: "Reset run time",
  VOL: "Reset Volume Counter",
  DriveSoftRstrt: "Restart the Drive"
};

const RESETPARAMS = {
  ENERGY: "APH",
  RUNTIME: "RTH",
  OPERATINGTIME: "PTH",
  DriveSoftRstrt: "DriveSoftRstrt",
  VC: "VOL"
};

const MOTORCONTROLPARAMS = {
  SensorlessFluxVectorV: "VVC",
  StandardMotorLaw: "STD",
  FivePointVoltageFrequencey: "UF5",
  SynchronousMotor: "SYN",
  VFQuadratic: "UFQ",
  EnergySaving: "NLD",
};

const FLOWCONTROLPARAMS = {
  NO: "0",
  PQ: "2"
};

const PVCSPARMAS = {
  NO: "0",
  SLFV: "1",
  SPUC: "2"
};

export {
  NAVHEADERS,
  NAV2HEADERS,
  NAV3HEADERS,
  RESETLABELS,
  CONFIGURATIONS,
  OptionValues,
  RESETPARAMS,
  MOTORCONTROLPARAMS,
  DIALOGCONTENT,
  FLOWCONTROLPARAMS,
  PVCSPARMAS
};
