import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../api/ApiSession'
import TableComponent from '../components/tableComponent';
import moment from 'moment'
import { useSelector } from 'react-redux'
import withCommonLandscape from '../hocs/withCommonLandscape';
const Reports = (props) => {
    const userObj = useSelector((state)=> state.user)
    const [tableData, setTableData] = React.useState([])
    const [tableDataCopy, setTableDataCopy] = React.useState([])
    const [tableHeader, setTableHeader] = React.useState([])
    const authCall = new Auth()
    let counter = 0
    const fetchData = () => {       
        authCall.getReportsData(sessionStorage.getItem("customerType"),sessionStorage.getItem("customerId")).then(json => {
            if (json) {
                setTableData(json.ReportDataResponse.ReportList)
                setTableDataCopy(JSON.parse(JSON.stringify(json.ReportDataResponse.ReportList)))
            }
        })
    }
    React.useEffect(() => {
        setTableHeader(["Site", "Type", "Created", "Download", ""])
        // fetch real-time data and trigger periodic refresh
        fetchData()
        props.onChange(moment().format('DD/MM/YYYY h:mm:ss a')) 
        let intervalId = setInterval(() => {
            if (counter <= 2) {
                fetchData()
                counter++
            }
        }, 5000)
        // return unmount handler
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const search = (e) => {
        let searchValue = e.target.value
        searchValue = searchValue.toLowerCase();
        let searchResult = tableDataCopy.filter(ele => {
            return (ele.SiteName).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.ReportName).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.CreatedDate).toLowerCase().indexOf(searchValue) !== -1
        })
        setTableData(searchResult)
    }

    const navigateToCreateReport = () => {
        let { history } = props;
        history.push({
            pathname: '/createReport'
        });
    }

    return <div>

<se-container display="block" padding="large">
    <div class="col-md-12 px-0">
        <div class="row justify-content-center">
        <div class="col-md-10 col-lg-10 col-xl-8 px-0 mobile-report-table">

        <se-block display="block" color="standard">
            <div class="col-md-12 pb-3 px-0 my-auto text-right  left-margin-mobile">
                <input class="mr-2 margin-search-box" type="search1" placeholder="Search" onChange={(e) => search(e)}/>
                <se-button option="flat" color="primary" className="text-right" onClick={navigateToCreateReport}>Create Report</se-button>
            </div>
        </se-block>

        <div>
            <TableComponent sortable={true} tableHeader={tableHeader} tableData={tableData} tableName='ReportsTable' />
            </div>

        </div>
        </div>
    </div>
</se-container>

        {/* <se-container option="centered">
            <span style={{ float: "right" }}>
                <se-form-field id="input-form">
                    <input style={{ width: "500px",height:"32px" }} onChange={(e) => search(e)} type="text" placeholder="Search" />
                    <se-button style={{width:"230px"}} option="flat" color="primary" onClick={navigateToCreateReport}>Create Report</se-button>
                </se-form-field>
            </span>
            <TableComponent sortable={true} tableHeader={tableHeader} tableData={tableData} />
        </se-container> */}
    </div>

}

export default withCommonLandscape(withRouter(Reports));