export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_IDMSERVER + '.well-known/openid-configuration',
  response_type: 'code',
  scope: 'openid profile email offline_access refresh_token',
  grant_type: 'authorization_code',
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  loadUserInfo: false, // "/oauth2/userInfo" call unsupported by Centrapay OAuth server
  post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  filterProtocolClaims: true,
};

export const METADATA_OIDC = {
  response_type: 'code',
  authorization_endpoint: process.env.REACT_APP_IDMSERVER + 'authorize',
  token_endpoint: process.env.REACT_APP_IDMSERVER + 'oauth/token',
  grant_type:"authorization_code",
  client_id: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_IDMSERVER,    
  redirect_uri:process.env.REACT_APP_REDIRECT_URL,
  scope: 'openid profile email offline_access refresh_token',
  loadUserInfo: true,
  automaticSilentRenew: true
};