import React, { Component } from 'react';
import land from '../images/landImg.png'
import agri from '../images/agri.PNG'
import solarwaterpumping from '../images/solarwaterpumping.PNG'
import { useDispatch } from 'react-redux'
import { addUser } from '../UserSlice'
import { user } from '../services/Crypto'
import withCommonLandscape from '../hocs/withCommonLandscape';

const LandingPage = (props) => {
  const dispatch = useDispatch()
  dispatch(addUser(user));
  
  const getAgroDetails = () => {
    sessionStorage.setItem("customerType", 'Agro');
    sessionStorage.setItem("customerId", parseInt(0));
    sessionStorage.setItem("siteName", "");
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  const getSWPDetails = () => {
    sessionStorage.setItem("customerType", 'SWP');
    sessionStorage.setItem("siteName", "");
    sessionStorage.setItem("customerId", parseInt(0));
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  return <se-container>
    <div className='container-fluid'>
      <div className='row' style={{ padding: '25px 15px' }}>
        <div className='col-6' style={{ borderRight: '2px solid #CBCBCB'}}>
          <div className='row'>
            <div className='col-md-12 col-lg-12 text-center'><img class="agri_landing_img" src={agri} style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col-md-12 landingTitle'>
              <span className='link_txt' onClick={getAgroDetails}>Villaya Agri</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center parafont' style={{ padding: '15px' }}>The Villaya Agri Digital Platform is a centralized system that integrates monitoring of sevral agricultural loads. It includes Impact measurement to farmers and their dependents, Capacity utilization, Income generated</p>
              <ui>
                <li><strong>Monitoring:</strong> Real-time monitoring of motor performance, energy production, water flow rate <br></br></li>
                <li><strong>User Interface:</strong> A user-friendly interface for visualization of data, performance metrics, and historical trends, often accessible via web or mobile applications <br></br></li>
                <li><strong>Reporting:</strong> Generation of reports and alerts to notify stakeholders about system performance, energy savings, and maintenance requirements</li>
              </ui>            
          </div>
          <div className='row' style={{float:'right',marginTop:'105px'}}>
            <se-button option="flat" color="primary" onClick={getAgroDetails}>Visit</se-button>
          </div>
        </div>
        <div className='col-6'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 text-center'><img class="swp_landing_img" src={solarwaterpumping} style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col-md-12 landingTitle'>
              <span className='link_txt' onClick={getSWPDetails}>Villaya Solar Water Pumping</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center parafont' style={{ padding: '15px'}}>The Villaya Solar Water Pumping Digital Platform is a centralized system that integrates data and controls for solar-powered water pumping systems. It typically includes features such as:</p>
            <ul style={{listStyleType:"revert",paddingLeft:'20px'}}> 
              <li><strong>Monitoring:</strong> Real-time monitoring of water pump performance, energy production, water flow rates, and system health <br></br></li>
              <li><strong>Remote Control:</strong> Ability to remotely start, stop, or adjust the operation of the water pumps based on the data and insights gathered <br></br></li>
              <li> <strong>Data Analysis:</strong> Utilization of data analytics to optimize water pumping efficiency, predict maintenance needs, and identify potential issues <br></br></li>
              <li><strong>User Interface:</strong> A user-friendly interface for visualization of data, performance metrics, and historical trends, often accessible via web or mobile applications <br></br></li>
              <li><strong>Reporting:</strong> Generation of reports and alerts to notify stakeholders about system performance, energy savings, and maintenance requirements</li>
            </ul>
          </div>
          <div className='row' style={{float:'right'}}>
            <se-button option="flat" color="primary" onClick={getSWPDetails}>Visit</se-button>
          </div>
        </div>
      </div>
    </div>

  </se-container>
}
export default withCommonLandscape(LandingPage)