import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment'
import { Bar } from 'react-chartjs-2'
import { user } from '../services/Crypto'
import withCommonLandscape from '../hocs/withCommonLandscape';

class UtilizedDetails extends Component {
    static defaultProps = {
        numberOfMonths: 1,
      };
    // intervalID = 0;
    constructor(props) {
        super(props);
        this.state = {
            unit:"(kWh)",
            granularity: "Daily",
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
        };
        // this.handleFromChange = this.handleFromChange.bind(this);
        // this.handleToChange = this.handleToChange.bind(this);
    }
    
    showFromMonth() {
        var { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }  

    initialState=()=>{
        this.setState({startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD')})
    }  
      handleFromChange(startDate) {
          console.log(startDate);
        this.setState({ from: startDate});
      }    
      handleToChange(endDate) {
        this.setState({ to: endDate }, this.showFromMonth);
        this.setState({ startDate: moment(this.state.from).format('YYYY-MM-DD') });
        this.setState({ endDate: moment(endDate).format('YYYY-MM-DD') })
        this.setState({ granularity: "Custom" }, () => {
            this.fetchUtiliteDetails();
        });
      }
     
      handleResetClick=() =>{  
          this.today();   
      } 
    today = () => {
        var todayDate = new Date();
        this.setState({ startDate: moment(todayDate).format('YYYY-MM-DD') });
        this.setState({ endDate: moment(todayDate).format('YYYY-MM-DD') })
        this.setState({ granularity: "Daily" }, () => {
            this.fetchUtiliteDetails();
        });

    }
    thisWeek = () => {
        var todayDate = new Date();
        var firstday = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay()));
        var lastday = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay()+6));
        // var startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
        this.setState({ startDate: moment(firstday).format('YYYY-MM-DD') });
        this.setState({ endDate: moment(lastday).format('YYYY-MM-DD') });
        this.setState({ granularity: "Custom" }, () => {
            this.fetchUtiliteDetails();
        });
    }
    thisMonth =()=>{
        var todayDate = new Date();
        var startDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
        var endDate =  new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);        
        this.setState({ startDate: moment(startDate).format('YYYY-MM-DD') });
        this.setState({ endDate: moment(endDate).format('YYYY-MM-DD') });
        this.setState({ granularity: "Monthly" }, () => {
            this.fetchUtiliteDetails();
        });
    }
    thisYear =()=>{
        var startDate = new Date(new Date().getFullYear(), 0, 1);
        var endDate = new Date(new Date().getFullYear()+1, 0, 0);
        this.setState({ startDate: moment(startDate).format('YYYY-MM-DD') });
        this.setState({ endDate: moment(endDate).format('YYYY-MM-DD') });
        this.setState({ granularity: "Yearly" }, () => {
            this.fetchUtiliteDetails();
        });
    }
    navigatetoOverview() {
        let { history } = this.props;
        if(this.props.history.location.widgetType == "CUSTOMER"){
        history.push({
            pathname: '/mycustomers'
        });             
    }else{
        history.push({
            pathname: '/overview'
        });
    }
    }
    componentDidMount() {
        sessionStorage.setItem("widgetType", this.props.history.location.widgetType);
        sessionStorage.setItem("customerId", this.props.history.location.customerId);
        this.fetchUtiliteDetails();
        this.initialState();
        this.intervalID = setInterval(this.fetchUtiliteDetails.bind(this), 900000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    fetchUtiliteDetails() {
        if(sessionStorage.getItem("widgetType") == "CUSTOMER"){
            var jsonBody = {
                userId: sessionStorage.getItem("customerId"),
                type: sessionStorage.getItem("widgetType"),
                granularity: this.state.granularity,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }; 
        }else{
        var jsonBody = {
            userId: user.userid,
            type: sessionStorage.getItem("widgetType"),
            granularity: this.state.granularity,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
        }
        fetch('http://104.211.205.108:8096/api/AgroDashboardDetails', {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                if(json.responseData != null && json.responseData != ''){                  
                    if(json.type == "AGRI PRODUCED"){  
                        this.setState({unit:"(Kg)"});
                    }else if(json.type == "REVENUE") {
                        this.setState({unit:"(INR)"});
                    } 
                        this.setState({type:json.type});             
                        this.setState({
                            labels: json.responseData.X,
                            data: json.responseData.Y,
                            SugarCane:json.responseData.SugarCane,
                            RiceMachine:json.responseData.RiceMachine,
                            Pump:json.responseData.Pump
                        });                
            }else{
                this.setState({
                    labels: '',
                    data: ''
                });
            }
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to }; 
    if(this.state.type =="AGRI PRODUCED"){
        var dataValue = {
            labels: this.state.labels,
            datasets: [
                {
                    label: "SugarCane",
                    backgroundColor: '#42B4E6',
                    borderColor: '#42B4E6',
                    borderWidth: 2,
                    data:this.state.SugarCane
                },
                {
                    label: "RiceMachine",
                    backgroundColor: '#D6E9C6',
                    borderColor: '#D6E9C6',
                    borderWidth: 2,
                    data: this.state.RiceMachine
                },
                // {
                //     label: "Pump",
                //     backgroundColor: '#EBCCD1',
                //     borderColor: '#EBCCD1',
                //     borderWidth: 2,
                //     data: this.state.Pump
                // }
            ]
        }
    }else{
        var dataValue = {
            labels: this.state.labels,
            datasets: [
                {
                    label: this.props.history.location.widgetType,
                    backgroundColor: '#42B4E6',
                    borderColor: '#42B4E6',
                    borderWidth: 2,
                    data:this.state.data
                }]
            }
    }
    const options= {
        animation: {
           duration: 10,
       },
       tooltips: {
                   mode: 'label',
                   backgroundColor: "white",
                           bodyFontColor: "black",
                           borderColor: '#42B4E6',
                           borderWidth: 1
        },
    scales: {
        xAxes: [{ 
            stacked: true, 
            gridLines: { display: false },
           }],
        yAxes: [{ 
            stacked: true,
            display: true,
            scaleLabel: {
            display: true,
            labelString: this.state.unit,
            }, 
            ticks: {
                beginAtZero: true
                }
           }],
       },
       legend: {
        display: true,
        position: 'bottom'
      }
   }
        // const options = {
        //     tooltips: {
        //         backgroundColor: "white",
        //         bodyFontColor: "black",
        //         borderColor: '#42B4E6',
        //         borderWidth: 1,
        //     },
        //     scales: {
        //         yAxes: [{
        //             tacked: true,
        //             // display: true,
        //             // scaleLabel: {
        //             //     display: true,
        //             //     labelString: "(kWh)",
        //             // },
        //             // ticks: {
        //             //     beginAtZero: true
        //             // }
        //         }],
        //         xAxes: [{
        //             tacked: true,
        //             // gridLines: {
        //             //     display: false
        //             // }
        //         }
        //         ]
        //     }
        // }
        return (
            <se-block display="flex" option="card" margin="small">
                <se-container>
                    <se-container position="absolute" direction="row">
                        <se-block>
                            <se-block-header class="headerColor"><span class="font-size-18">{sessionStorage.getItem("widgetType")}</span>
                                <div slot="end"><se-icon option="button" onClick={this.navigatetoOverview.bind(this)}>action_close</se-icon></div>
                            </se-block-header>
                            <se-block-content>
                                <div className="dataBoader">
                                    <se-container display="flex">
                                        <se-block width="500px">
                                            <se-block-header>
                                                <div slot="start" class="font-size-12"></div>
                                            </se-block-header>
                                            <se-block-header>
                                            <div slot="start" className="row font-size-12 lpd-10">
                                                    <div><se-icon size="small">calendar</se-icon></div>
                                                    <div><button type="button" onClick={this.today}>Today</button>
                                                    <button type="button" onClick={this.thisWeek}>This Week</button>
                                                    <button type="button" onClick={this.thisMonth}>This Month</button>
                                                    <button type="button" onClick={this.thisYear}>This Year</button></div>
                                                    {/* <DayPicker selected={this.state.startDate}  onChange={(d)=>{this.dateChanged(this.state.startDate=d)}} /> */}
                                                </div>
                                            </se-block-header>
                                            <se-block-header>                                                
                                    <div slot="start" class="font-size-12">Custom Date :&nbsp;
        <DayPickerInput value={this.state.startDate} placeholder="From" format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate} dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange.bind(this)}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput ref={el => (this.to = el)} value={this.state.endDate} placeholder="To" format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
            }}
            onDayChange={this.handleToChange.bind(this)}
          />
        </span>&nbsp;
        <button type="button" onClick={this.handleResetClick}>Reset</button>
        <Helmet>
          <style>{`
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
        </Helmet>
        </div>
                                            </se-block-header>
                                        </se-block>
                                        <se-divider option="vertical"></se-divider>
                                        <se-block>
                                            <se-block-header>
                                                <div slot="end">
                                                    <se-icon option="button" color="alternative" onClick={this.navigatetoOverview.bind(this)}>exit_presentation</se-icon>
                                                    <se-icon option="button" color="alternative">other_vertical</se-icon>
                                                </div>
                                            </se-block-header>
                                            <se-block-content>
                                                <div class="chartData">
                                                    <Bar data={dataValue} options={options} />
                                                </div>
                                                <div>
                                                </div>
                                            </se-block-content>
                                        </se-block>
                                    </se-container>
                                </div>

                            </se-block-content>
                        </se-block>
                    </se-container>
                </se-container>
                {/* <se-block-header>
                        <span class="font-size-18">{headerName}</span>
                        <div slot="end">
                        <se-icon option="button" color="alternative">
                        presentation_mode
                            </se-icon>
                            <se-icon option="button" color="alternative">
                            action_pin
                            </se-icon>
                            <se-icon option="button" color="alternative">
                            other_vertical
                            </se-icon>
                            
                        </div>
                        </se-block-header>
                        <se-block-content>
                        <CanvasJSChart options = {options}   />
                             
                            
                        </se-block-content> */}


            </se-block>

        );
    }
}
export default withCommonLandscape(withRouter(UtilizedDetails));