import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import loginBackground from '../images/operations.jpg'
import Auth from '../api/ApiSession';
import AuthService from '../services/AuthService';
import withCommonLandscape from '../hocs/withCommonLandscape';
const authCall = new Auth();
// const _auth = new AuthService();

class Login extends Component {
    constructor(props) {
        // _auth.startAuthentication()
        super(props);
        this.state = {
            userid: '',
            password: '',
            message: '',
            errormessage: '',
            loginStatus: ''
        };
        this.loginUser = this.loginUser.bind(this);
        sessionStorage.setItem("loggedInTime",0)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

    }
    onButtonClick() {
        if (!this.state.userid) {
            this.setState({ errorMessage: "Please enter user name" });
            console.log(this.state);
            document.getElementById('snackbar').open = true;
            document.getElementById('snackbar').canClose = true;
        } else if (!this.state.password) {
            this.setState({ errorMessage: "Please enter your passwrd" });
            console.log(this.state);
            document.getElementById('snackbar').open = true;
            document.getElementById('snackbar').canClose = true;
        } else {
            this.loginUser();
            // let { history } = this.props;
            // history.push({
            //     pathname: '/overview'
            // });

        }
    }



    loginUser() {
        sessionStorage.setItem("customerType", null);
        sessionStorage.setItem("loggedInTime",0)
        var jsonBody = {
            userName: this.state.userid,
            password: this.state.password
        };
        authCall.loginUser(jsonBody).then(json => {
            this.state.loginStatus = json.Table[0].loginStatus;
           
            if (this.state.loginStatus > 0) {
                // this.fetchUserPersonalInfo(json);
                sessionStorage.setItem("customerId", parseInt(0));
                sessionStorage.setItem("siteName", "");
                // sessionStorage.setItem("token",json['data'][0].token);
                sessionStorage.setItem("loginUserName", json.Table[0].display_name);
                if (json.Table[0].user_type === "Agro" || json.Table[0].user_type === "SWP") {
                    sessionStorage.setItem("customerType", json.Table[0].user_type);
                    let { history } = this.props;
                    history.push({
                        pathname: '/overview'
                    });
                } else {
                    sessionStorage.setItem("customerType", json.Table[0].user_type);
                    let { history } = this.props;
                    history.push({
                        pathname: '/Landing'
                    });
                }



                //     setTimeout(() => {
                //         let { history } = this.props;
                // history.push({
                //     pathname: '/overview'
                // }); 
                // window.location.reload(false);
                // }, 500);
            }
            else {
                this.setState({ errorMessage: "Invalid User Credentials" });
                console.log(this.state);
                document.getElementById('snackbar').open = true;
                document.getElementById('snackbar').canClose = true;
            }
        })
            .catch(error => {

                console.log(error);
            });

    }

    // fetchUserPersonalInfo(json) {
    //     authCall.getUserProfileInfo(json).then(UserDetails => {
    //         sessionStorage.setItem("loginUserName" ,UserDetails.FirstName +" "+ UserDetails.LastName);
    //     })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    onSubmit() {

        let { history } = this.props;
        history.push({
            pathname: '/signup'
        });
    }

    render() {

        const src = "url(" + loginBackground + ")";
        return (
            <div className="main-login-screen">
                <se-authentication app-title="Energy Access Livelihood" version="1.0" image-url={src} className="">
                    <form onSubmit={e => e.preventDefault()}>
                        <se-form-field option="stacked">
                            <input type="email" placeholder="Email" id="email" name="userid" value={this.state.userid} onChange={e => this.handleChange(e)} />
                        </se-form-field>
                        <se-form-field option="stacked">
                            <input type="password" id="pwd" name="password" placeholder="Password" value={this.state.password} onChange={e => this.handleChange(e)} />
                        </se-form-field>
                        {/* <div className="form-group mb-2">
                            <input type="email" className="form-control" id="email" name="userid" placeholder="Email" value={this.state.userid} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group mb-2">
                            <input type="password" className="form-control" id="pwd" name="password" placeholder="Password" value={this.state.password} onChange={e => this.handleChange(e)} />
                        </div> */}
                        <se-button type="submit" option="login" onClick={this.onButtonClick.bind(this)}>Login</se-button>
                        {/* <button type="button" className="btn btn-success btn-md btn-block" onClick={this.onButtonClick.bind(this)} >Login</button> */}
                        <div className="form-group mt-2">
                            <a href="#">Forgot your password ?</a>
                            {/* <a href="#" className="float-right" onClick={this.onSubmit.bind(this)}>Sign Up</a> */}
                            <a href="#" className="float-right">Sign Up</a>
                            <se-snackbar id="snackbar" type="error" icon="information_stroke" message={this.state.errorMessage} ></se-snackbar>
                        </div>
                    </form>
                </se-authentication>
            </div>

        );
    }
}

export default withCommonLandscape(withRouter(Login));