import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _, { result } from 'lodash';
import './styles.css';
import AzureMapControls from '../components/azureMapControls';
import validate from './validateSignUpForm'
import useForm from './useSignUpForm';
import withCommonLandscape from '../hocs/withCommonLandscape';
const SingUp = (props) => {
    const { values, handleChange, handleSubmit, updateMapLatLong, errors, handletermsChk, responseData } = useForm(validate)
    const [isRegisterBtnDisabled, setIsRegisterBtnDisabled] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState("")
    const [snackbarType, setSnackbarType] = React.useState("")
    const [valuesFromMap, setValueFromMap] = React.useState(values.Zipcode)
    const handleCancel = () => {
        let { history } = props;
        history.push({
            pathname: '/'
        });
    }
    const handleZipCodeChange = (e) => {
        setValueFromMap(e.target.value)
    }

    React.useEffect(() => {
        _.map(errors, (ele) => {
            _.map(errors, (innerEle, innerIndex) => {
                if (ele === innerIndex) {
                    if (innerEle) {
                        setErrorMsg(innerEle)
                        setSnackbarType('error')
                        document.getElementById('signup-snackbar').open = true;
                    }
                }
            })
        })
        let str = JSON.stringify(errors)
        let n = str.search("error-border");
        n === -1 ? setIsRegisterBtnDisabled(false) : setIsRegisterBtnDisabled(true)
        _.map(values, (ele, index) => {
            if (ele == '' && index !== 'State' && index !== 'City' && index !== 'Zipcode') {
                setIsRegisterBtnDisabled(true)
            }
        })
    }, [errors])

    React.useEffect(() => {
        if (responseData === "User Already Exist") {
            setSnackbarType('error')
            setErrorMsg(responseData)
            document.getElementById('signup-snackbar').open = true;
        } else if (responseData === "User created Sucessfully") {
            setSnackbarType('success')
            setTimeout(() => {
                let { history } = props;
                history.push({
                    pathname: '/'
                }); 
            }, 2000);            
            setErrorMsg(responseData)
            document.getElementById('signup-snackbar').open = true;
        }

    }, [responseData])

    React.useEffect(() => {
        if(values){
            setValueFromMap(values.Zipcode)
        }
        console.log(values)
    }, [values])



    return <se-container option="centered">
        <form onSubmit={handleSubmit}>
            <div className="row signup-form">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 se-white-background mt-2">
                    <se-form-field label="User Type" required="true" type="select">
                        <select name="UserType" onBlur={handleChange} style={{
                            width: "100%", height: '28px', padding: "0px 5px",
                            backgroundPosition: "calc(100% - 19px) calc(1em + -4px), calc(100% - 15px) calc(1em + -4px)"
                        }}>
                            <option value="Agro">Agro</option>
                            <option value="SWP">SWP</option>
                        </select>
                    </se-form-field>
                    <se-form-field label="Prefix" required="true" type="select">
                        <select name="Prefix" onBlur={handleChange} style={{
                            width: "100%", height: '28px', padding: "0px 5px",
                            backgroundPosition: "calc(100% - 19px) calc(1em + -4px), calc(100% - 15px) calc(1em + -4px)"
                        }}>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="M/S">M/S</option>
                        </select>
                    </se-form-field>

                    <se-form-field label="First Name" required="true">
                        <input type="text" onBlur={handleChange} className={errors.FirstNameClass} name="FirstName" placeholder="FirstName" autoComplete="off" maxlength="100"
                        />

                    </se-form-field>
                    <se-form-field label="Last Name" required="true">
                        <input type="text" onBlur={handleChange} className={errors.LastNameClass} name="LastName" placeholder="LastName" autoComplete="off" maxlength="100"
                        />
                    </se-form-field>
                    <se-form-field label="Email" required="true">
                        <input type="text" onBlur={handleChange} name="EmailId" placeholder="EmailId" className={errors.EmailIdClass} autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="Password" required="true">
                        <input type="password" onBlur={handleChange} name="Password" className={errors.PasswordClass} placeholder="Password" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="Confirm Password" required="true">
                        <input type="password" onBlur={handleChange} className={errors.ConfirmPasswordClass} name="ConfirmPassword" placeholder="ConfirmPassword" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="ContactNo" required="true">
                        <input type="text" onBlur={handleChange} name="ContactNo" className={errors.ContactNoClass} placeholder="ContactNo" autoComplete="off" maxLength="10"
                        />
                    </se-form-field>

                    {/* <se-form-field label="Company Name" required="true">
                        <input type="text" onBlur={handleChange} name="CompanyName" placeholder="CompanyName" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field id="select-form" label="Company Address" required="true">
                        <input type="text" onBlur={handleChange} name="CompanyAddress" className={errors.CompanyAddressClass} placeholder="CompanyAddress" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="Industry Name" required="true">
                        <input type="text" onBlur={handleChange} name="Industry" placeholder="Industry" autoComplete="off" maxLength="100"
                        />
                    </se-form-field> */}
                    <se-form-field id="select-form" label="Country" required="true" disabled="true">
                        <input type="text" value={values.Country} name="Country" placeholder="Country" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="State" disabled="true">
                        <input type="text" value={values.State} name="State" placeholder="State" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>
                    <se-form-field label="City" disabled="true">
                        <input type="text" value={values.City} name="City" placeholder="City"
                        />
                    </se-form-field>
                    <se-form-field label="Time Zone" required="true" disabled="true">
                        <input type="text" value={values.Timezone} name="Timezone" placeholder="Timezone" autoComplete="off" maxLength="100"
                        />
                    </se-form-field>

                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 se-white-background mt-2 sing-up">
                    <se-form-field>
                        <AzureMapControls isDisabled={false} onClick={updateMapLatLong} classStyle="azureMapControlsStyle"/>
                    </se-form-field>
                    <se-form-field label="Pincode" >

                        <input type="text"  onBlur={handleChange} onChange={handleZipCodeChange} value={valuesFromMap} className={errors.ZipcodeClass} name="Zipcode" placeholder="Zipcode" autoComplete="off" maxLength="6"
                        />
                    </se-form-field>

                    <se-form-field label="Address" required="true">
                        <textarea onBlur={handleChange} style={{ height: '15vh' }} className={errors.AddressClass} name="Address" placeholder="Address" autoComplete="off" maxLength="100">

                        </textarea>
                        {/* <input type="text" onBlur={handleChange} className={errors.AddressClass} name="Address" placeholder="Address" autoComplete="off" maxLength="100"
                        /> */}
                    </se-form-field>

                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" onChange={handletermsChk} checked={values.terms} class="custom-control-input" name="terms" id="customControlValidation1" />
                        <label class="custom-control-label" for="customControlValidation1">I have read, understood and agree with the Terms Of Use and the Data Privacy Statement</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="remoteMonitoringControl" id="customControlValidation2" />
                        <label class="custom-control-label" for="customControlValidation2">I agree to recieve informtion on products and promotional materials from Schneider Electric</label>
                    </div>



                    <se-dialog id="dialog-simple-notitle">
                        <se-dialog-header>User Profile</se-dialog-header>
                        <se-dialog-content>
                            Profile Updated Successfully
        </se-dialog-content>
                        <se-dialog-footer></se-dialog-footer>
                    </se-dialog>
                    <se-form-field>
                        <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  text-right">
                                <se-snackbar id="signup-snackbar" duration="5000" type={snackbarType} icon="information_stroke" message={errorMsg} ></se-snackbar>
                                <se-button option="outline" onClick={handleCancel}>Cancel</se-button>
                                <se-button option="flat" color="primary" type="submit" disabled={isRegisterBtnDisabled}>Register</se-button>
                            </div>
                        </div>
                    </se-form-field>
                </div>
            </div>
        </form>
    </se-container>
}
export default withCommonLandscape(SingUp)
