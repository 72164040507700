import React, { useEffect, useRef, useState } from "react";
import { CONFIGURATIONS } from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import Auth from "../../api/ApiSession";
import _, { set } from "lodash";
import style from './startStop.module.css';

const authCall = new Auth();
const minDate = new Date().toISOString().slice(0, 10); // Format today's date for minDate
const styles = {
  startStopStyles: {
    margin: "4px 0 4px 1px",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "2%",
  },
  selectStyles: {
    minWidth: "100%",
    borderRadius: "3px",
    maxHeight: "34px",
    lineHeight: "16px",
    fontSize: "16px",
    fontWeight: "500",
    padding: "6px 32px 6px 8px",
  },
  buttonStyles: {
    minWidth: "35%",
    marginLeft: "2%",
  },
  redBtnEnable: {
    color: "red"
  },
  redBtnDisable: {
    color: "red",
    visibility: "collapse"
  },
  height: {
    height: "25px"
  },
  transaction:{
    float: "right",
    paddingTop: "10px",
    color: "red"
  },
  tablecss:{
    padding: "0px 10px",
    "line-height": "1.2",
    "font-size": "14px",
    "white-space": "nowrap",
    "border": "none"
  },
  submitStyles:{
    marginTop:"15px"
  }
};

const StartStop = ({ deviceData, setDeviceData }) => {  
  const [deviceSettings, setDeviceSettings] = useState({});
  const [isMotorMsg, setMotorMsg] = useState(false);
  const [snackBarObj, setSnackBarObj] = React.useState({});
  const [remoteControl, setRemoteControl] = useState(false);
  const [isRemoteModal, setIsRemoteModal] = useState(false);
  const [mode, setMode] = useState();
  const [isChecked, setIsChecked] = useState({timer: false, demand: false});
  const [dayList, setDayList] = React.useState([{ value: "Monday", selected: false },{ value: "Tuesday", selected: false },{ value: "Wednesday", selected: false },{ value: "Thursday", selected: false },{ value: "Friday", selected: false },{ value: "Saturday", selected: false },{ value: "Sunday", selected: false }])
  const [demandList, setDemandList] = React.useState({"Mon":0,"Tue":0,"Wed":0,"Thu":0,"Fri":0,"Sat":0,"Sun":0,"Validity":""});
  const [timerList, setTimerList] = React.useState({startTime:"",endTime:"",duration:"",timervalidity:""});
  const [formUpdated, setFormUpdated] = useState(false);
  const [gatewayDetails, setGatewayDetails] = useState({});
  const [isDemandValid, setIsDemandValid] = useState({Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true, Sun: true, Validity: true});
  const [isTimerValid, setIsTimerValid] = useState({startTime: true, endTime: true, timervalidity: true});
  const [isInProgress, setIsInProgress] = useState(false);

  useEffect(() => {
    fetchDeviceData(deviceData.DeviceId);
    DataUpdated(); 
  }, []);  

  useEffect(() => {
    DataUpdated();
    validateData();
    formValid();
    duration();
  }, [mode,gatewayDetails,demandList,timerList]);  

  const fetchDeviceData = (DeviceId) => {
    authCall
      .getConfigurationData(CONFIGURATIONS.GetData, { DeviceId: DeviceId, customerId: sessionStorage.getItem("customerId") })
      .then((res) => {
        if(res[0].Status === "Remote"){
          setRemoteControl(res[0].RemoteStartStop === 1 ? true : false);
        }else{
          setRemoteControl(false);
        }
        setDemandList({"Mon":res[0].day1water,"Tue":res[0].day2water,"Wed":res[0].day3water,"Thu":res[0].day4water,"Fri":res[0].day5water,"Sat":res[0].day6water,"Sun":res[0].day7water,"Validity":res[0].watervalidity});
        setTimerList({startTime:res[0].starttime,endTime:res[0].endtime,duration:res[0].Duration,timervalidity:res[0].timervalidity});
        setDeviceSettings({...res[0],DeviceId});
        setGatewayDetails(res[0]);
        res[0].mode === 1 ? modeSelection(1,!isChecked.timer) : modeSelection(2,!isChecked.demand);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFrequencyChange = ({ target: { value } }) => {  
    const result = value.replace(/\D/g, '');
    if (value.length > 3) {
      return;
    }    
    setDeviceData({
      ...deviceData,
      Frequency: result,
    });   
  };  

  const getTransaction = () => {
    authCall.getTransactionData(CONFIGURATIONS.GetTransaction,sessionStorage.getItem("customerId"), 'Wait,InProgress').then(json => {      
      if (json.length>0) {        
        var motorArray = json.filter(function(cmd){ return cmd.commandName === 'StartOrStop'; });
        var water = json.filter(function(cmd){ return cmd.commandName === 'WaterReq'; });
        
        if(motorArray.length >0){
          setMotorMsg(true);
        }
        if(water.length > 0){
          setIsInProgress(true);
        }
      }
    })
  };

  const handleConfirm = () => {
    setIsRemoteModal(false);
    authCall
      .putUpdateStartStop(CONFIGURATIONS.Reset, {
        DeviceId: deviceData.DeviceId,
        TopicName: remoteControl ? "Start" : "Stop",
        ResetCommand: remoteControl ? "Start" : "Stop",
        ResetType: "True",
        customerId: sessionStorage.getItem("customerId")
      })
      .then((response) => {
        document.getElementById('dialog-complex').open = false;
        alert();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const alert = () => {
    document.getElementById('remote-snackbar').open = true;
    setSnackBarObj({ type: "success", msg: "Command Initiated successfully." });
  };

  const handleModalClose = () => {
    setIsRemoteModal(false);
    setRemoteControl(!remoteControl);
  };

  const Clicked = (event) => {
      document.getElementById('dialog-complex').open = true;             
      setRemoteControl(!remoteControl);
      setIsRemoteModal(!isRemoteModal);
  }

  const Canceled = (event) => {
      document.getElementById('dialog-complex').open = false;
      setRemoteControl(!remoteControl);
      setIsRemoteModal(!isRemoteModal);
  }

  const modeSelection = (data,select) => {
    if(data === 1){
      setIsChecked({timer: select, demand: false});
    }else if(data === 2){
      setIsChecked({timer: false, demand: select});
    }
    let _mode = select === false ? '' : data;
    setMode(_mode); 
  }

  function selectAll() {
    var x = document.getElementsByClassName("checkbox");
    let selected = !x[0].selected;
    for(var i=0; i<x.length; i++) {
       x[i].selected = selected;
     }   
  }

  const DemandChange = (e) => {
    e.persist();
    let data;
    if(e.target.name.toString().trim() === 'Validity'){
      data = e.target.value.toString("dd-mm-yyyy");
    }else{
      data = parseFloat(e.target.value);
    }
    setDemandList(prevState => ({ ...prevState, [e.target.name]: data }));
  }

  const TimerChange = (e) => {
    e.persist();
    let data;
    if(e.target.name.toString().trim() === 'timervalidity'){
      data = e.target.value.toString("dd-mm-yyyy");
    }else{
      data = e.target.value.toString();
    }
    setTimerList(prevState => ({ ...prevState, [e.target.name]: data }));
  }

  const validateData = () => {
    let isDemandValid = {Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true, Sun: true, Validity: true};    
    if(mode === 1){
      _.map(dayList, (item, index) => {
        if(demandList[item.value.substring(0,3)] < 0 || demandList[item.value.substring(0,3)] > 4800 || isNaN(demandList[item.value.substring(0,3)])){          
          isDemandValid[item.value.substring(0,3)] = false;
        }
      });
      
      if(demandList.Validity === ""){
        isDemandValid.Validity = false;
      }
      setIsDemandValid(isDemandValid);
    }
    else if(mode === 2){
      let isTimerValid = {startTime: true, endTime: true, timervalidity: true};
      if(timerList.startTime === "" || validateTime(timerList.startTime) === false){
        isTimerValid.startTime = false;
      } if(timerList.endTime === "" || validateTime(timerList.endTime) === false){
        isTimerValid.endTime = false;
      } if(timerList.timervalidity === "" || validateDate(timerList.timervalidity) === false){
        isTimerValid.timervalidity = false;
      }

      // validate end time should be greater than start date 
      if(validateTime(timerList.startTime) && validateTime(timerList.endTime)){
        let start = timerList.startTime.split(":");
        let end = timerList.endTime.split(":");
        let hourDiff = end[0] - start[0];
        let minDiff = end[1] - start[1];

        if((hourDiff === 0 && minDiff <= 0) || hourDiff < 0){
          isTimerValid.endTime = false;
        }
      }

      setIsTimerValid(isTimerValid);
    }    
  }

  const validateDate = (date) => {
    var date_regex = /^\d{4}\-\d{2}\-\d{2}$/ ;
    return date_regex.test(date);
  }

  const validateTime = (time) => {
    var date_regex = /^\d{2}\:\d{2}$/ ;
    return date_regex.test(time);
  }

  const DataUpdated = () => {
    let isEnable = true;
    if(mode === 1 && (gatewayDetails.day1water !== demandList.Mon && demandList.Mon || gatewayDetails.day2water !== demandList.Tue || gatewayDetails.day3water !== demandList.Wed || gatewayDetails.day4water !== demandList.Thu || gatewayDetails.day5water !== demandList.Fri || gatewayDetails.day6water !== demandList.Sat || gatewayDetails.day7water !== demandList.Sun || gatewayDetails.watervalidity !== demandList.Validity)){
      isEnable = false;
    }
    else if (mode === 2 && (gatewayDetails.starttime !== timerList.startTime || gatewayDetails.endtime !== timerList.endTime || gatewayDetails.timervalidity !== timerList.timervalidity)){
      isEnable = false;
    }
    setFormUpdated(isEnable);  
  }

  const formValid = () => {      
    let submit = true;  
    
    if(formUpdated === false){
      submit= false;
    }if(mode === 1 && (isDemandValid.Mon === false || isDemandValid.Tue === false || isDemandValid.Wed === false || isDemandValid.Thu === false || isDemandValid.Fri === false || isDemandValid.Sat === false || isDemandValid.Sun === false || isDemandValid.Validity === false)){
      submit = true;
    }if(mode === 2 && (isTimerValid.startTime === false || isTimerValid.endTime === false || isTimerValid.timervalidity === false)){
      submit = true;
    }if(isInProgress === true){
      submit = true;
    }
    console.log("vailidity", isDemandValid.Validity)
    console.log("submit", submit)
    return submit;
  }

  const duration = () => {
    let start = timerList.startTime.split(":");
    let end = timerList.endTime.split(":");
    let hourDiff = end[0] - start[0];
    let minDiff = 0;
    if(hourDiff === 0){
      minDiff = end[1] - start[1];
    }else if(end[1] < start[1]){
      minDiff = ((60-parseInt(start[1])) + parseInt(end[1]));
      hourDiff=hourDiff-1;
    }else{
      minDiff = end[1] - start[1];
    }
    
    console.log("hours", hourDiff)
    console.log("minutes", minDiff)
    return hourDiff + 'h ' + minDiff + 'm';
  }

  const setData = () => {
    let data = []; let jsonData = {};
    if(mode === 1){
      jsonData = {"Status":"Enabled","Mon":((demandList.Mon)*10).toString(),"Tue":((demandList.Tue)*10).toString(),"Wed":((demandList.Wed)*10).toString(),"Thu":((demandList.Thu)*10).toString(),"Fri":((demandList.Fri)*10).toString(),"Sat":((demandList.Sat)*10).toString(),"Sun":((demandList.Sun)*10).toString(),"Validity":new Date(demandList.Validity).toISOString().split('T')[0]};
      data = ([{ cust_id: sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId, isProcessed: 0, commandName: "WaterReq", commandType: JSON.stringify({mode: mode}), commandStatus: "Wait", commandValue: JSON.stringify(jsonData) }]);
    }
    else if(mode === 2){
      //For converting start time to UTC time
      let reqStartTime=new Date(`1970-01-01T${timerList.startTime}:00+05:30`).toUTCString().slice(17, 22);
      let reqDuration=duration().replace(" ", ":");
      jsonData = {"Status":"Enabled","StartTime":reqStartTime,"Duration":reqDuration,"Validity":new Date(timerList.timervalidity).toISOString().split('T')[0]};
      data = ([{ cust_id: sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId, isProcessed: 0, commandName: "TimerMode", commandType: JSON.stringify({mode: mode}), commandStatus: "Wait", commandValue: JSON.stringify(jsonData) }]);
    }
    authCall
    .postTransaction(data)
    .then((response) => {
        alert("Command Initiated successfully.", "success");
        getTransaction();
    })
    .catch((error) => {
        console.log(error);
    });
  }

  const CheckboxComponent = () => {
    return <div>
            <table id="demandTable">
            <thead>
              <tr style={styles.tablecss}>
                {/* <th style={{border: 'none'}} class="text-center"><span><se-checkbox onClick={()=>selectAll()} style={{display: 'inline-table'}}></se-checkbox></span></th> */}
                <th style={{border: 'none'}} class="text-center"><span>Day</span></th>               
                <th style={{border: 'none'}} class="text-center"><span>&nbsp;&nbsp;Required(kL/d)&nbsp;&nbsp;</span></th>   
                <th style={{border: 'none'}} class="text-center"><span>&nbsp;&nbsp;Discharged(kL/d)&nbsp;&nbsp;</span></th>             
              </tr>
            </thead>
            <tbody>
            {_.map(dayList, (item, index) => {
              return (<tr style={styles.tablecss} key={index}>
                <td style={styles.tablecss}><span>{item.value}<span style={styles.redBtnEnable}>*</span></span></td>             
                <td style={styles.tablecss}>
                  <se-form-field disabled={isInProgress}>
                    <input type="number" disabled={isInProgress} onChange={DemandChange} defaultValue={demandList[item.value.substring(0,3)]} name={item.value.substring(0,3)} onPaste={(e) => { e.preventDefault(); return false; }} className={style.inputStyles} placeholder="0 to 4800" min="0" max="4800" title="" />
                  </se-form-field>
                  <span style={isDemandValid[item.value.substring(0,3)] === false ? styles.redBtnEnable : styles.redBtnDisable}>Length is between 0 to 4800</span>
                </td>
                <td style={styles.tablecss}>
                  <se-form-field disabled={true}>
                    <input type="number" defaultValue="2000" name="nominalMotorSpeed" onPaste={(e) => { e.preventDefault(); return false; }} className={style.inputStyles} placeholder="500 to 5000" min="500" max="5000" title="" />
                  </se-form-field>
                </td>
                
              </tr>)
            }) }
            <tr style={styles.tablecss}>
              <td style={styles.tablecss}>Validity<span style={styles.redBtnEnable}>*</span></td>
              <td style={styles.tablecss}>
                <se-form-field disabled={isInProgress}>
                <input disabled={isInProgress} type="date" onChange={DemandChange} defaultValue={demandList.Validity} name='Validity' min={minDate} className={style.dateStyles} />
                </se-form-field>
                <span style={isDemandValid.Validity === false ? styles.redBtnEnable : styles.redBtnDisable}>Date is not a valid format</span>
              </td>           
            </tr>
            </tbody>
            </table>                        
          </div>;
  };
  
  return (
    <se-container position="grid" direction="row" margin="large" padding="large" class="start-stopMonbile">
      <se-block-content class={style.content}>
                    
        <div className="row createBlockSize">
          <div className="col-xl-1 col-xl-2 col-md-3">
            <label style={styles.startStopStyles}>Status</label>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-5 col-sm-2">
            <se-form-field disabled><input type="text" value={deviceSettings.Status} style={styles.selectStyles} name="status" /></se-form-field>
          </div>
        </div>

        <div className="row createBlockSize">
          <div className="col-xl-1 col-xl-2 col-md-3">
            <p style={styles.startStopStyles}>{CONFIGURATIONS.StartStop}</p>
          </div>
          <div className="col-xl-1 col-lg-3 col-md-5 col-sm-2">
            <div className="d-flex">
              <p className="mr-3">Off</p>
                <se-checkbox style={styles.height} checked={deviceSettings.Status === "Remote" ? true : false} disabled={deviceSettings.Status === "Remote" ? false : true} id="toggle" 
                option="switch" label-pos="right" selected={remoteControl} onClick={Clicked}></se-checkbox>
              <p className="ml-3">On</p>
              <p style={isMotorMsg==true? styles.redBtnEnable : styles.redBtnDisable}>{CONFIGURATIONS.Motor_Inprogress_Msg}</p>
          </div>
          </div>
        </div>                   

        <div className="row createBlockSize">
          <div className="col-xl-1 col-xl-2 col-md-3">
            <p style={styles.startStopStyles}>Operating Mode</p>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-5 col-sm-2 d-flex">
            <se-checkbox selected={isChecked.timer} onClick={() => modeSelection(1,!isChecked.timer)} id="demand" label="Water Demand" style={{paddingRight: '12px'}}></se-checkbox>
            <se-checkbox selected={isChecked.demand} onClick={() => modeSelection(2,!isChecked.demand)} id="timer" label="Timer"></se-checkbox>
          </div>
        </div>

        <div className="row createBlockSize weekdays">
          <div className="col-xl-1 col-xl-2 col-md-3">
            {  mode === 1 &&  <p style={styles.startStopStyles}>Select Days</p> }            
          </div>
          <div className="col-xl-1 col-lg-3 col-md-5 col-sm-2">
            {  mode === 1 &&  CheckboxComponent() }            
          </div>
        </div>

        {  mode === 2 && <div>
          <div className="row createBlockSize">
            <div className="col-xl-1 col-xl-2 col-md-3"><p style={styles.startStopStyles}>Start Time <span style={styles.redBtnEnable}>*</span></p></div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-2">
              <se-form-field disabled={isInProgress}><input disabled={isInProgress} onChange={TimerChange}  value={timerList.startTime} type="time" id="startTime" name="startTime" className={style.dateStyles} /></se-form-field>
            </div>
          </div>
          <div className="row createBlockSize">
            <div className="col-xl-1 col-xl-2 col-md-3"><p style={styles.startStopStyles}>End Time <span style={styles.redBtnEnable}>*</span></p></div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-2"><se-form-field disabled={isInProgress}><input disabled={isInProgress} onChange={TimerChange} value={timerList.endTime} type="time" id="endTime" name="endTime" min="12:50" max="23:59" className={style.dateStyles} /></se-form-field></div>
          </div>
          <div className="row createBlockSize">
            <div className="col-xl-1 col-xl-2 col-md-3"><p style={styles.startStopStyles}>Duration</p></div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-2"><se-form-field disabled><input type="text" className={style.dateStyles} value={duration()} ></input></se-form-field> </div>
          </div>
          <div className="row createBlockSize">
            <div className="col-xl-1 col-xl-2 col-md-3"><p style={styles.startStopStyles}>Validity<span style={styles.redBtnEnable}>*</span></p></div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-2">
              <se-form-field disabled={isInProgress}><input disabled={isInProgress} onChange={TimerChange} value={timerList.timervalidity} type="date" id="timervalidity" name="timervalidity" min={minDate} className={style.dateStyles} /></se-form-field>
              <p style={isTimerValid.timervalidity === false ? styles.redBtnEnable : styles.redBtnDisable}>Date is not a valid format</p>
            </div>
          </div>
        </div>
        }
        
        <div className="row createBlockSize">
          <div className={isChecked.demand === true ? 'col-xl-2  col-md-3' : 'col-xl-2  col-md-4'}>
            <span style={isInProgress === true ? styles.transaction : styles.redBtnDisable}>{'Transaction InProgress'}</span>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-5 col-sm-2"><span>{isChecked.demand}</span>
            <se-form-field disabled={formValid()}>
              <se-button disabled={formValid()} size="small" option="flat" color="primary" style={styles.submitStyles} onClick={() => setData()}>{CONFIGURATIONS.Set}</se-button>
            </se-form-field>
          </div>
        </div>

        <div class="paddingLeft300" align="left">
          <se-snackbar id="remote-snackbar" duration="5000" type={snackBarObj.type}
            message={snackBarObj.msg}>            
          </se-snackbar>
        </div>
      </se-block-content>

      <se-dialog id="dialog-complex" color="alternative" can-backdrop="false" size="small">
          <se-dialog-header>Please Confirm</se-dialog-header>
          <se-dialog-content>
            <div class="font-size-14 pb-4">
              { remoteControl ? CONFIGURATIONS.Dialog_Start_Content : CONFIGURATIONS.Dialog_Stop_Content}
            </div>
          </se-dialog-content>
          <se-dialog-footer align="right">
            <se-button onClick={() => Canceled()}>
              Cancel
            </se-button>
            <se-button onClick={() => handleConfirm()}>OK</se-button>
          </se-dialog-footer>
        </se-dialog>
    </se-container>
  );
};
export default StartStop;

StartStop.propTypes = {
  slider: PropTypes.string,
};
