import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Pagination from "react-js-pagination";
import 'bootstrap/scss/bootstrap.scss';
import HighPriority from '../images/high1.png';
import MediumPriority from '../images/medium1.png'
import LowPriority from '../images/low.png'
import Auth from '../api/ApiSession';
import moment from 'moment'
import _ from 'lodash';
import { user } from '../services/Crypto';
import withCommonLandscape from '../hocs/withCommonLandscape';
const authCall = new Auth();
const isMobile = window.innerWidth <= 600;
let postPerPage = 6;

class Notifications extends Component {
    // intervalID = 0;
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            siteName: "",
            customerId: sessionStorage.getItem("customerId"),
            highPriority: false,
            mediumPriority: false,
            lowPriority: false,
            notificationDuration: 1,
            upArrow: true,
            notificationTable: [],
            currentPage: 1,
            curentButtonActive: true,
            historyButtonActive: false,
            currentPost: [],
            successType: "",
            successMsg: "",
            count: 0,
            NotificationStatus: [],
            Assets: [],
            searchText:""

        }
    }
    componentWillMount() {
        this.onCurrentStatus();
        if(isMobile){
            postPerPage = 4;
        }
    }
    componentWillUnmount() {
        // clearInterval(this.intervalID);
    }
    onCurrentStatus() {
        this.setState({
            currentStatus: true, curentButtonActive: true, historyButtonActive: false, notificationDuration: 1, currentPage: 1
        }, () => {
            this.fetchNotificationsData();
            this.fetchAgroSiteList();
            this.fetchNotificationsDataSelectedCustomer()
        }
        );
        this.setState({searchText:""})

    }
    onHistoryStatus() {
        this.setState({
            currentStatus: false, curentButtonActive: false, historyButtonActive: true, notificationDuration: 0, currentPage: 1
        }, () => {
            this.fetchNotificationsData();
            this.fetchAgroSiteList();
            this.fetchNotificationsDataSelectedCustomer()
        }
        );
        this.setState({searchText:""})
    }
    fetchAgroSiteList() {
        authCall.getAgroCustomersList(sessionStorage.getItem("customerType")).then(entries => {
            this.setState({ siteList: entries });
            this.setState({ CustomerlistLength: entries.length });
        }).catch(error => {
            console.log(error);
        });
    }
    fetchNotificationsData() {
        authCall.getNotificationData().then(json => {
            this.setState({ NotificationCategory: json.NotificationCategory });
            this.setState({ NotificationStatus: json.NotificationStatus });
            this.setState({ Assets: json.Assets });
            this.setState({ Events: json.Events });
        }).catch(error => {
            console.log(error);
        });
    }
    fetchNotificationsDataSelectedCustomer() {
        let date = moment.utc().format();
        let local = moment.utc(date).local().utcOffset();
        let jsonBody = {
            "customerId": parseInt(this.state.customerId),
            "siteName": this.state.siteName,
            "notificationDuration": this.state.notificationDuration,
            "customerType": sessionStorage.getItem("customerType"),
            "TimeZoneOffset": local
        };
        authCall.postNotifications(jsonBody).then(json => {
            this.setState({ notificationTable: json.ResponseData.NotificationDetails })
            this.setState({ notificationData: json.ResponseData.NotificationDetails })
            this.tableFilter();
        })
            .catch(error => {
                console.log(error);
            });
    }
    notificationacknowledge() {
        var jsonBody = {
            "customerId": sessionStorage.getItem("customerId"),
            "notificationId": this.state.notificationId,
            "acknowledgementType": 1,
            "notificationStatus": this.state.notificationStatus,
            "acknowledgeBy": sessionStorage.getItem("loginUserName")
        };
        authCall.postNotificationAcknowledge(jsonBody).then(json => {
            document.getElementById('snackbar_success').open = true;
            this.setState({ successMsg: json });
            this.setState({ successType: "success" });
            this.fetchNotificationsDataSelectedCustomer()
        })

    }
    onChange = (notificationId, acknowledgeValue, notificationStatus, acknowledgeBy) => {
        this.setState({ notificationId: notificationId })
        this.setState({ acknowledgeBy: acknowledgeBy })
        this.setState({ notificationStatus: notificationStatus },
            () => {
                this.notificationacknowledge();
                // console.log("notificationId", this.state.notificationId, this.state.acknowledgeBy, this.state.notificationStatus)
            });
    }

    sortTable = (property) => {
        this.setState({ upArrow: false })
        let updatedNotificationTable = this.state.notificationTable.sort((a, b) => {
            if (typeof a[property] === 'number' && typeof b[property] === 'number') {
                if (a[property] < b[property]) {
                    return -1
                }
                else if (a[property] > b[property]) {
                    return 1
                }
                else {
                    return 0;
                }
            } else {
                var reA = /[^a-zA-Z]/g;
                var reN = /[^0-9]/g;
                if (typeof a[property] === 'number') {
                    a[property] = a[property].toString();
                } else {
                    a[property] = a[property].charAt(0).toUpperCase() + a[property].slice(1);
                }

                if (typeof b[property] === 'number') {
                    b[property] = b[property].toString();
                } else {
                    b[property] = b[property].charAt(0).toUpperCase() + b[property].slice(1);
                }
                var aA = a[property].replace(reA, "");
                var bA = b[property].replace(reA, "");
                if (aA === bA) {
                    var aN = parseInt(a[property].replace(reN, ""), 10);
                    var bN = parseInt(b[property].replace(reN, ""), 10);
                    if (isNaN(bN) || isNaN(bN)) {
                        return a[property] > b[property] ? 1 : -1;
                    }
                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                } else {
                    return aA > bA ? 1 : -1;
                }

            }
        })
        this.setState({ notificationTable: updatedNotificationTable })
        this.paginate(1);
    };

    sortTableDes = (property) => {
        this.setState({ upArrow: true })
        // console.log(this.state.notificationTable, property)
        let updatedNotificationTable = this.state.notificationTable.sort((b, a) => {
            if (typeof a[property] === 'number' && typeof b[property] === 'number') {
                if (a[property] < b[property]) {
                    return -1
                }
                else if (a[property] > b[property]) {
                    return 1
                }
                else {
                    return 0;
                }
            } else {
                var reA = /[^a-zA-Z]/g;
                var reN = /[^0-9]/g;
                if (typeof a[property] === 'number') {
                    a[property] = a[property].toString();
                } else {
                    a[property] = a[property].charAt(0).toUpperCase() + a[property].slice(1);
                }

                if (typeof b[property] === 'number') {
                    b[property] = b[property].toString();
                } else {
                    b[property] = b[property].charAt(0).toUpperCase() + b[property].slice(1);
                }
                var aA = a[property].replace(reA, "");
                var bA = b[property].replace(reA, "");
                if (aA === bA) {
                    var aN = parseInt(a[property].replace(reN, ""), 10);
                    var bN = parseInt(b[property].replace(reN, ""), 10);
                    if (isNaN(bN) || isNaN(bN)) {
                        return a[property] > b[property] ? 1 : -1;
                    }
                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                } else {
                    return aA > bA ? 1 : -1;
                }

            }
        })
        this.setState({ notificationTable: updatedNotificationTable })
        this.paginate(1);
    };
    handleChange(e) {
        this.setState({ isLoading: true })
        this.setState({ customerId: e.target.value }, () => {
            this.fetchNotificationsDataSelectedCustomer()
        });
        let siteList = this.state.siteList;

        for (let i = 0; i <= siteList.length - 1; i++) {
            if (siteList[i].customerId == e.target.value) {
             var siteName = siteList[i].location;
             break;
            }
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'Notification_Site_Dropdown',
      'SiteName':siteName});
    }
    priorityIcon = (Priority) => {
        let PriorityStatus;
        if (Priority === 1) {
            PriorityStatus = <img src={HighPriority} width="24" height="24" />
        } else if (Priority === 2) {
            PriorityStatus = <img src={MediumPriority} width="24" height="24" />
        } else if (Priority === 3) {
            // PriorityStatus = <se-icon size="small" color="secondary">information_circle</se-icon>
            PriorityStatus = <img src={LowPriority} width="21" height="21" />
        }
        return PriorityStatus
    }
    assetTypeValue = (AssetValue, assetDetails) => {
        if (this.state.Assets.length > 0) {
            let finallAssetValue;
            if (AssetValue === 1) {
                finallAssetValue = <a class="hasTooltip">{this.state.Assets[0].assetType}
                    <div class="font-size-14">
                        <span class="rows"> Asset Type :{this.state.Assets[0].assetType} </span>
                        <span class="rows"> Details :{assetDetails.details}</span>
                        <span class="rows"> Customer:{assetDetails.customerName}</span>
                        <span class="rows"> Phone : {assetDetails.customerPhone}</span>
                    </div>
                </a>

            } else if (AssetValue === 2) {
                finallAssetValue = <a class="hasTooltip">{this.state.Assets[1].assetType}
                    <div class="font-size-14">
                        <span class="rows"> Asset Type :{this.state.Assets[1].assetType} </span>
                        <span class="rows"> Details :{assetDetails.details}</span>
                        <span class="rows"> Customer:{assetDetails.customerName}</span>
                        <span class="rows"> Phone : {assetDetails.customerPhone}</span>
                    </div>
                </a>
            } else if (AssetValue === 3) {
                finallAssetValue = <a class="hasTooltip">{this.state.Assets[2].assetType}
                    <div class="font-size-14">
                        <span class="rows"> Asset Type :{this.state.Assets[2].assetType} </span>
                        <span class="rows"> Details :{assetDetails.details}</span>
                        <span class="rows"> Customer:{assetDetails.customerName}</span>
                        <span class="rows"> Phone : {assetDetails.customerPhone}</span>
                    </div>
                </a>
            } else if (AssetValue === 4) {
                finallAssetValue = <a class="hasTooltip">{this.state.Assets[3].assetType}
                    <div class="font-size-14">
                        <span class="rows"> Asset Type :{this.state.Assets[3].assetType} </span>
                        <span class="rows"> Details :{assetDetails.details}</span>
                        <span class="rows"> Customer:{assetDetails.customerName}</span>
                        <span class="rows"> Phone : {assetDetails.customerPhone}</span>
                    </div>
                </a>
            } else if (AssetValue === 5) {
                finallAssetValue = <a class="hasTooltip">{this.state.Assets[4].assetType}
                    <div class="font-size-14">
                        <span class="rows"> Asset Type :{this.state.Assets[4].assetType} </span>
                        <span class="rows"> Details :{assetDetails.details}</span>
                        <span class="rows"> Customer:{assetDetails.customerName}</span>
                        <span class="rows"> Phone : {assetDetails.customerPhone}</span>
                    </div>
                </a>
            }
            return finallAssetValue
        }
    }
    eventStatus = (Status) => {
        if (this.state.NotificationStatus.length > 0) {
            let finallStatus;
            if (Status === 1) {
                finallStatus = <div class="chipStatus font-size-14" align="center">{this.state.NotificationStatus[0].statusType}</div>
                // <se-chip value="In progress" color="standard" size="nano"></se-chip>
            } else if (Status === 2) {
                finallStatus = <div class="chipStatus font-size-14" align="center">{this.state.NotificationStatus[1].statusType}</div>
            } else if (Status === 3) {
                finallStatus = <div class="chipStatus font-size-14" align="center">{this.state.NotificationStatus[2].statusType}</div>
            } else if (Status === 4) {
                finallStatus = <div class="chipStatus font-size-14" align="center">{this.state.NotificationStatus[3].statusType}</div>
            } else if (Status === 5) {
                finallStatus = <div class="chipStatus font-size-14" align="center">{this.state.NotificationStatus[4].statusType}</div>
            }
            return finallStatus
        }
    }
    acknowledgeStatus = (notificationId, acknowledgeValue, notificationStatus, acknowledgeBy) => {
        let finallStatus;
        if (acknowledgeValue === 0) {
            if (this.state.currentStatus) {
                finallStatus = <button class="acknowledge" onClick={(e) => this.onChange(notificationId, acknowledgeValue, notificationStatus, acknowledgeBy)}>Acknowledge</button>

            } else {
                finallStatus = <button class="acknowledge font-size-14" disabled>Acknowledge</button>
            }
        } else if (acknowledgeValue === 1) {
            finallStatus = <button class="unAcknowledge font-size-14" disabled>Acknowledge</button>
        }
        return finallStatus
    }
    LoadTable = (Notifications, index) => {
        return (
            <tr style={{ "text-align": "center", "border-bottom": "none", border: "none" }}>
                <td style={{ "text-align": "center", border: "none", padding: "0.55rem" }}>{this.priorityIcon(Notifications.priorityType)}</td>
                <td className="hideinMobile" style={{ "text-align": "center", color: "#219BFD", border: "none", padding: "0.55rem", "text-decoration": "underline" }}>{Notifications.notificationId}</td>
                <td className="hideinMobile" style={{ "text-align": "left", border: "none", padding: "0.55rem" }}>{Notifications.siteName}</td>
                <td className="hideinMobile" style={{ "text-align": "left", color: "#219BFD", border: "none", padding: "0.55rem", "text-decoration": "underline" }}>{this.assetTypeValue(Notifications.assetType, Notifications.assetDetails)}</td>
                <td style={{ "text-align": "left", border: "none", padding: "0.55rem" }}>{Notifications.notificationDescription}</td>
                <td style={{ "text-align": "left", border: "none", padding: "0.55rem", fontSize: "75%"}}>{Notifications.notificationTime}</td>
                <td style={{ "text-align": "left", border: "none", padding: "0.55rem" }}>{this.eventStatus(Notifications.notificationStatus)}</td>
                <td className="hideinMobile" style={{ "text-align": "center", border: "none", padding: "0.55rem" }}>{this.acknowledgeStatus(Notifications.notificationId, Notifications.acknowledgementType, Notifications.notificationStatus, Notifications.acknowledgeBy)}</td>
                <td className="hideinMobile" style={{ "text-align": "center", border: "none", padding: "0.55rem" }}>{Notifications.acknowledgeBy}</td>
            </tr>
        )
    }
    onClickHighPriority() {
        const currentState = this.state.highPriority;
        this.setState({
            highPriority: !currentState
        }, () => {
            this.tableFilter();
        });
        // console.log("highPriority", this.state.highPriority)
    }
    onClickMediumPriority() {
        const currentState = this.state.mediumPriority;
        this.setState({
            mediumPriority: !currentState
        }, () => {
            this.tableFilter();
        });
        // console.log("mediumPriority", this.state.mediumPriority)
    }
    onClickLowPriority() {
        const currentState = this.state.lowPriority;
        this.setState({
            lowPriority: !currentState
        }, () => {
            this.tableFilter();
        });
    }
    tableFilter() {
        let self = this;
        let tempArray = []
        this.state.notificationData.forEach((ele) => {
            if (self.state.highPriority === true && self.state.mediumPriority === false && self.state.lowPriority === false) {
                if (ele.priorityType !== 1) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === false && self.state.mediumPriority === true && self.state.lowPriority === false) {
                if (ele.priorityType !== 2) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === false && self.state.mediumPriority === false && self.state.lowPriority === true) {
                if (ele.priorityType !== 3) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === true && self.state.mediumPriority === true && self.state.lowPriority === false) {
                if (ele.priorityType == 3) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === true && self.state.mediumPriority === false && self.state.lowPriority === true) {
                if (ele.priorityType == 2) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === false && self.state.mediumPriority === true && self.state.lowPriority === true) {
                if (ele.priorityType == 1) {
                    tempArray.push(ele)
                }
            } else if (self.state.highPriority === true && self.state.mediumPriority === true && self.state.lowPriority === true) {
                tempArray = []
            } else {
                tempArray.push(ele)
            }
        })
        this.state.notificationTable = tempArray
        this.setState({ notificationTable: tempArray })
        this.paginate(this.state.currentPage);
        this.setState({ isLoading: false })
    }
    paginate(pageNumber) {
        this.setState({ currentPage: pageNumber })
        let from = ((pageNumber - 1) * postPerPage);
        let to = pageNumber * postPerPage
        this.setState({ currentPost: this.state.notificationTable.slice(from, to) })
    }
    searchText(value) {
        let searchString = value.toLowerCase()
        this.setState({searchText:value})
        let responseData = []
        if (searchString.length > 0) {
            responseData = this.state.notificationData.filter(item => {
                return item.notificationDescription.toLowerCase().indexOf(searchString) !== -1;
            })
            this.state.notificationTable = responseData
        }else{
            this.state.notificationTable = this.state.notificationData 
        }
        
        this.paginate(1);
        
        window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({'event': 'Search_Notification'});
        window.dataLayer.push({'event': 'Search_Notification',
        'Search_Text': value});

        
    }
    
    render() {
        if (this.state.count === 0) {
            this.props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
            this.setState({ count: this.state.count + 1 })
        }
        const options = [];
        options.push(<option value="0">All Sites</option>);
        for (let i = 0; i <= this.state.CustomerlistLength - 1; i++) {
            const siteList = this.state.siteList;
            if (siteList[i].location) {
                options.push(<option value={siteList[i].customerId}>{siteList[i].location}</option>);
            }
        }
        return (
            <div class="grey-backgroundCreate" >
                {this.state.isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
                <se-container padding="large" direction="column" class="grey-backgroundCreate" >
                    <se-block color="standard" align="center" height="50px">
                        <div class="rows grey-backgroundCreate" style={{textAlign:"center"}}>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" align="center">
                                <div >
                                    <button class={this.state.curentButtonActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={() => this.onCurrentStatus()}>Current</button>
                                    <button class={this.state.historyButtonActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={() => this.onHistoryStatus()}> History</button>
                                </div>
                            </div>
                        </div>
                    </se-block>
                    <div padding="" direction="column" color="standard">
                        <div display="flex">
                            <div>
                                <div class="row text-right">
                                    <div class="hideinMobile col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <se-form-field id="select-form" label="Site :" required="false" padding="none" type="select" option="inline">
                                            <select name="CustomerName" value={this.state.customerId} onChange={(e) => this.handleChange(e)}>
                                                {options}
                                            </select>
                                        </se-form-field>

                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <se-form-field  label="Search :" required="false" padding="none" option="inline">
                                            <input placeholder="search by event desc" type="text" value={this.state.searchText} onChange={(e) => this.searchText(e.target.value)} style={{ height: "40px" }} />
                                        </se-form-field>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-12 col-lg-12 pull-right my-auto disply-mobile">

                                        <span className="priority pr-2">Priority <span className='hideinMobile'>:</span></span>
                                        {this.state.highPriority ?
                                            <div class="chipSelect pointer" onClick={() => this.onClickHighPriority()} align="left">
                                                <img src={HighPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;High</span>
                                            </div>
                                            : <div class="chip pointer" onClick={() => this.onClickHighPriority()} align="left">
                                                <img src={HighPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;High</span>
                                            </div>}&nbsp;&nbsp;
                                {this.state.mediumPriority ?
                                            <div class="chipSelect pointer" onClick={() => this.onClickMediumPriority()} align="left">
                                                <img src={MediumPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;Medium</span>
                                            </div>
                                            :
                                            <div class="chip pointer" onClick={() => this.onClickMediumPriority()} align="left">
                                                <img src={MediumPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;Medium</span>
                                            </div>}&nbsp;&nbsp;
                            {this.state.lowPriority ?
                                            <div class="lowchipSelect pointer" onClick={() => this.onClickLowPriority()} align="left">
                                                <img src={LowPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;Low</span>
                                            </div>
                                            :
                                            <div class="lowchip pointer" onClick={() => this.onClickLowPriority()} align="left">
                                                <img src={LowPriority} />
                                                <span>&nbsp;&nbsp;&nbsp;Low</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <se-block display="flex" padding="medium">
                            <div className="notifications-component">
                                <div className="bs-example nav-content">
                                    <table id="myTable" class="table" >
                                        <thead className = "notification-font-mobile">
                                            <tr style={{ border: "none" }}>
                                                <th style={{ "text-align": "center", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Priority</th>
                                                <th className="hideinMobile" style={{ "text-align": "center", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Event ID {this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('notificationId')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('notificationId')}>arrow3_down</se-icon>}</th>
                                                <th className="hideinMobile" style={{ "text-align": "left", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Site {this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('siteName')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('siteName')}>arrow3_down</se-icon>}</th>
                                                <th className="hideinMobile" style={{ "text-align": "left", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Asset Type {this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('assetType')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('assetType')}>arrow3_down</se-icon>}</th>
                                                <th style={{ "text-align": "left", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}><span className = "hideinMobile">Event</span> Description{this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('notificationDescription')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('notificationDescription')}>arrow3_down</se-icon>}</th>
                                                <th style={{ "text-align": "left", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.35rem" }}>Created{this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('notificationTime')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('notificationTime')}>arrow3_down</se-icon>}</th>
                                                <th style={{ "text-align": "left", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Status {this.state.upArrow ? <se-icon size="nano" class="pointer" onClick={() => this.sortTable('notificationStatus')}>arrow3_up</se-icon> : <se-icon size="nano" class="pointer" onClick={() => this.sortTableDes('notificationStatus')}>arrow3_down</se-icon>}</th>
                                                <th className="hideinMobile" style={{ "text-align": "center", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Action</th>
                                                <th className="hideinMobile" style={{ "text-align": "center", "border-bottom": "none", "border-top": "none", border: "none", padding: "0.55rem" }}>Acknow. User</th>
                                            </tr>
                                        </thead>

                                        <tbody id="notificationTable" style={{ "text-align": "center", border: "none" }}>
                                            {this.state.currentPost.map(this.LoadTable)}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination class="pull-right" activePage={this.state.currentPage} itemsCountPerPage={postPerPage} totalItemsCount={this.state.notificationTable.length} onChange={this.paginate.bind(this)} />
                            </div>
                        </se-block>
                    </div>
                </se-container>
                <div class="row justify-content-md-center">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <se-snackbar id="snackbar_success" duration="5000" type={this.state.successType} icon="" message={this.state.successMsg} ></se-snackbar>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCommonLandscape(withRouter(Notifications));