import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.css';
import { user } from '../services/Crypto'
import withCommonLandscape from '../hocs/withCommonLandscape';

class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        firstname:"",
        lastname:"",
        email:"",
        companyname:"",
        companyaddress: '',
        contactnumber:""
    }
    this.createUser = this.createUser.bind(this);
    this.setUserDetails = this.setUserDetails.bind(this);
      }
    
    onBackButtonClick() {
        let { history } = this.props;
        history.push({
            pathname: '/settings'
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

    }

    componentWillMount() {
        this.fetchUser();
    }
    
    onEditButtonClick() {
        var editDiv = document.getElementById("onedit");
        var onLoad = document.getElementById("onLoad");
        editDiv.className= "buttondisplay";
        onLoad.className="buttonhide";
    }

    onSubmitButtonClick() {
        var editDiv = document.getElementById("onedit");
        var onLoad = document.getElementById("onLoad");
        editDiv.className= "buttonhide";
        onLoad.className="buttondisplay";
        this.createUser();
    }

    createUser() {
        var jsonBody = {
                    userId:user.userid,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    companyName:this.state.companyname,
                    companyAddress:this.state.companyaddress,
                    contactNumber:this.state.contactnumber,
        };

        fetch('http://localhost:8808/edituserprofile', {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonBody)
        })
            .then(response => {

                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                    document.getElementById('dialog-simple-notitle').open = true;
                
            })
            .catch(error => {
                console.log(error);

            });
    }

    fetchUser(){
        fetch('http://localhost:8808/getuserprofile/' + user.userid, {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
  
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                this.setUserDetails(json['data'][0]);
            })
            .catch(error => {
                console.log(error);
  
            });
        
    }
  
    setUserDetails(userDetails){
            this.setState({firstname : userDetails.firstName, 
                            lastname: userDetails.lastName,
                            email: userDetails.email,
                            contactnumber : userDetails.contactNo,
                            companyname:userDetails.companyName,
                            companyaddress : userDetails.address,
            });
    }

    render() {
        return (
        <div className="container profile_fontsize">
            <div id="onLoad">
            {/* <se-block className="container mt-3" option="fill"> */}
            <div class="col-md-12 text-center bg-light">
                <div class="cheatSheetIcon se-icon profileImage">user_standard</div>
            </div>
            <div class="col-md-12 bg-light border-gray border-bottom pt-2">
                <label class="text-left"><strong>Profile</strong></label>
            </div>
            <div class="col-md-12">
                <div class="row border-gray border-bottom pt-2">
                    <label class="col-md-3 text-left">Email</label>
                    <span class="col-md-9 text-right">{this.state.email}</span>
                </div>

                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">FirstName</label>
                        <span class="col-md-9 text-right">{this.state.firstname}</span>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">LastName</label>
                        <span class="col-md-9 text-right">{this.state.lastname}</span>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">CompanyName</label>
                        <span class="col-md-9 text-right">{this.state.companyname}</span>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">Company Address</label>
                        <span class="col-md-9 text-right">{this.state.companyaddress}</span>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">Contact number</label>
                        <span class="col-md-9 text-right profile_fontsize">{this.state.contactnumber}</span>
                    </div>
                </div>
                </div>




                <div className="container profile_fontsize">
                <div id="onedit" className="buttonhide">
            {/* <se-block className="container mt-3" option="fill"> */}
            <div class="col-md-12 text-center bg-light">
                <div class="cheatSheetIcon se-icon profileImage">user_standard</div>
            </div>
            <div class="col-md-12 bg-light border-gray border-bottom pt-2">
                <label class="text-left"><strong>Profile</strong></label>
            </div>
            <div class="col-md-12">
                <div class="row border-gray border-bottom pt-2">
                    <label class="col-md-3 text-left">Email</label>
                    <input type="text" className="col-md-9 text-right" name="email" placeholder="Email" autoComplete="off" maxLength="100"
                    value={this.state.email} onChange={e => this.handleChange(e)} />
                </div>

                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">FirstName</label>
                        <input type="text" className="col-md-9 text-right" name="firstname" placeholder="Email" autoComplete="off" maxLength="100"
                    value={this.state.firstname} onChange={e => this.handleChange(e)} />
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">LastName</label>
                        <input type="text" className="col-md-9 text-righ" name="lastname" placeholder="firstname" autoComplete="off" maxLength="100"
                    value={this.state.lastname} onChange={e => this.handleChange(e)} />
        </div>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">CompanyName</label>
                        <input type="text" className="col-md-9 text-right" name="companyname" placeholder="Email" autoComplete="off" maxLength="100"
                    value={this.state.companyname} onChange={e => this.handleChange(e)} />
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">Company Address</label>
                        <textarea class="col-md-9 text-right" value={this.state.companyaddress}></textarea>
                    </div>
                    <div class="row border-gray border-bottom pt-2">
                        <label class="col-md-3 text-left">Contact number</label>
                        <input type="text" className="col-md-9 text-right profile_fontsize" name="contactnumber" placeholder="Email" autoComplete="off" maxLength="100"
                    value={this.state.contactnumber} onChange={e => this.handleChange(e)} />
                    </div>
                </div>
                </div>

        <se-dialog id="dialog-simple-notitle">
        <se-dialog-header>User Profile</se-dialog-header>
        <se-dialog-content>
            Profile Updated Successfully
        </se-dialog-content>
        <se-dialog-footer></se-dialog-footer>
        </se-dialog>

    <div class="col-md-12">
        <div className="row mt-1">
            <div class="col-md-12 text-right px-0">
                <se-button option="flat" color="primary" onClick={this.onEditButtonClick.bind(this)}>Edit</se-button>
                <se-button option="flat" color="primary" onClick={this.onSubmitButtonClick.bind(this)}>Submit</se-button>
            </div>
        </div>
    </div>


        </div>
            
        );
    }
}


export default withCommonLandscape(MyProfile);