import { IDENTITY_CONFIG, METADATA_OIDC } from "./authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import Auth from '../api/ApiSession';
import CryptoJS from 'crypto-js'

const authCall = new Auth();
export default class AuthService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        });
        // // Logger
        // Log.logger = console;
        // Log.level = Log.DEBUG;
        // this.UserManager.events.addUserLoaded((user) => {
        //     if (window.location.href.indexOf("signin-oidc") !== -1) {
        //         this.navigateToScreen();
        //     }
        // });
        // this.UserManager.events.addSilentRenewError((e) => {
        //     console.log("silent renew error", e.message);
        // });

        // this.UserManager.events.addAccessTokenExpired(() => {
        //     console.log("token expired");
        //     this.logout();
        // });
    }

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect();
    };

    signinRedirectCallback = async () => {
        console.log("signinRedirectCallback");
        await this.UserManager.signinRedirectCallback().then(user => {
            //localStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("loginUserName", user.profile.name);
            sessionStorage.setItem("id_token", user.id_token);
            sessionStorage.setItem("expires_at", user.expires_at);
            sessionStorage.setItem("access_token", user.access_token);
            //sessionStorage.setItem("refresh_token", user.refresh_token);
            let value = {
                "Prefix": "Mr",
                "FirstName": user.profile.given_name,
                "LastName": user.profile.family_name,
                "EmailId": user.profile.email,
                "FederatedId": user.profile.custom_attributes.federatedId,
                "Address": "",
                "City": "",
                "State": "",
                "Country": "",
                "Timezone": "",
                "IDMSUserId": "",
                "Zipcode": ""
            }
            // await this.createUser(value)
            // sessionStorage.setItem("loggedInTime",0)

            // this.navigateToScreen()
            return authCall.postUserProfileData(value)
        }).then(response => {
            console.log(response)
            if (response[0].userid) {        
                const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:response[0].userid,user_type:response[0].user_type}), process.env.REACT_APP_SECRET_KEY).toString();
                sessionStorage.setItem("user", encrypted);
                //sessionStorage.setItem("siteName", "");
                //sessionStorage.setItem("customerId", parseInt(0));
                sessionStorage.setItem("loggedInTime", 0);
                sessionStorage.setItem("gatewayType", "");
                sessionStorage.setItem("gatewayType-mobile", "");                
                if (response[0].user_type !== null) {
                    this.navigateToScreen();
                }
                else {
                    window.location.replace("/unauthorized");
                }
            }
            else {
                // this.logout();
               console.log(response[0]);
            }
        }).catch((error) => {
            console.log(error);
            window.location.replace("/");
            // this.logout();
        });
    };



    getUser = async () => {
        const user = await this.UserManager.getUser()
        .then(function (user) {
            console.log('check User');
            if (!user) {
                console.log('User refreshed');
                return this.UserManager.signinRedirectCallback();
            }else{
                console.log('User Not refreshed');
            }
            return user;
       });        
    };

    // renews token using refresh token
    async renewToken() {
        const user = await this.UserManager.signinSilent();
        console.log(user);
        return user;
    }

    // refreshUser = async() => {
    //     const user  = await this.UserManager.getUser()
    //     .then(function (user) { 
    //         console.log('check User');
    //         if (user.expired) {
    //             console.log('User refreshed');
    //             this.UserManager.signinSilent();
    //             this.UserManager.signinSilentCallback();
    //         }else{
    //             console.log('User Not refreshed');
    //         }
    //     });        
    //   }

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    navigateToScreen = () => {
        window.location.replace("/Landing");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`))

        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        // this.UserManager.signinRedirectCallback
        window.location.href= process.env.REACT_APP_LOGOFF_REDIRECT_URL;
        // this.UserManager.signoutRedirect({
        //     app: "EcostruXureLivelihood",
        //     id_token_hint: sessionStorage.getItem("id_token"),            
        // });
        sessionStorage.clear();
        console.log('after logout click')
        this.UserManager.clearStaleState();
    };


    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            // localStorage.clear();
            console.log(window.location.pathname)
            // window.location.replace("/UserLogin");
            window.location.replace(process.env.REACT_APP_GLOBAL_AUTHORIZATION_CODE);
        });
        // this.UserManager.signinRedirect({
        //     extraQueryParams: { "app": "EcostruXureLivelihood"}
        //   });
        this.UserManager.clearStaleState();
    };
}
