import React, { useState, useEffect } from 'react';
import Auth from '../api/ApiSession';
import TableComponent from '../components/tableComponent';
import withCommonLandscape from '../hocs/withCommonLandscape';

const Usertickets = () => {
  const authCall = new Auth();
  const [userticketsData, setTicketsData] = useState([]);
  const [isCreateTicketActive, setCreateTicketActive] = useState(true);
  const [isMyTicketActive, setMyTicketActive] = useState(false);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedGateway, setSelectedGateway] = useState('');
  const [customerMessage, setCustomerMessage] = useState('');
  const [siteList, setSiteList] = useState([]);
  const [gatewayList, setGatewayList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [successType, setSuccessType] = useState('');
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const fetchAgroSiteList = async () => {
      try {
        const entries = await authCall.getAgroCustomersList(sessionStorage.getItem("customerType"));
        setSiteList(entries);
      } catch (error) {
        console.error('Error fetching site list:', error);
      }
    };

    fetchAgroSiteList();
  }, []);


  useEffect(() => {
    const fetchAgroGatewayList = async () => {
      if (selectedSite) {
        try {
          const entries = await authCall.getDiagnosticsList(sessionStorage.getItem("customerType"));
          const reqdSite = siteList.filter(site => site.location === selectedSite);
          const customerList = reqdSite.map(site => site.Customers);
          const filteredGateways = entries.filter(gateway => customerList.some(customer => customer === gateway.SiteName));
          setGatewayList(filteredGateways);
        } catch (error) {
          console.error('Error fetching gateway list:', error);
        }
      } else {
        setGatewayList([]); 
      }
    };
    fetchAgroGatewayList();
  }, [selectedSite]);

 
  useEffect(() => {
    const fetchMyTicketsData = async () => {
      if (isMyTicketActive) {
        try {
          const response = await authCall.getTicketsList(sessionStorage.getItem('customerType'), sessionStorage.getItem('customerId'));
          if (response) {
            setTicketsData(response.TicketDataResponse.TicketsList);
          }
        } catch (error) {
          console.error('Error fetching ticket data:', error);
        }
      }
    };
    fetchMyTicketsData();
  }, [isMyTicketActive]);

  useEffect(() => {
    setIsFormValid(selectedSite !== '' && selectedGateway !== '' && customerMessage.length <= 500);
  }, [selectedSite, selectedGateway, customerMessage]);

  const onCurrentStatus = () => {
    setCreateTicketActive(true);
    setMyTicketActive(false);
  };

  const onHistoryStatus = () => {
    setCreateTicketActive(false);
    setMyTicketActive(true);
  };

  const handleSiteChange = (event) => {
    setSelectedSite(event.target.value);
  };

  const handleGatewayChange = (event) => {
    setSelectedGateway(event.target.value);
  };

  const handleCustomerMessageChange = (event) => {
    const newMessage = event.target.value.slice(0, 500);
    setCustomerMessage(newMessage);
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    const ISTOffset = 330;
    const date = new Date(Date.now() + ISTOffset * 60000);
    const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
    const dataToPost = {
      GatewayId: selectedGateway,
      SiteName: selectedSite,
      CustomerMessage: customerMessage,
      CustomerId: parseInt(sessionStorage.getItem("customerId")),
      TicketStatus: 'Open',
      InsertedDate: formattedDate,
    };
    postTicketsDataList(dataToPost);
  };

  const postTicketsDataList = async (dataToPost) => {
    setIsLoading(true);
    try {
      const response = await authCall.postTicketsList(dataToPost);
      setIsLoading(false);
      setSelectedSite('');
      setSelectedGateway('');
      setCustomerMessage('');
      document.getElementById('snackbar_success').open = true;
      setSuccessMsg(response);
      setSuccessType("success");
    } catch (error) {
      setIsLoading(false);
      console.error('Error posting ticket data:', error);
    }
  };

  const fetchMyTicketsData = async () => {
    try {
      const response = await authCall.getTicketsList(sessionStorage.getItem('customerType'), sessionStorage.getItem('customerId'));
      if (response) {
        setTicketsData(response.TicketDataResponse.TicketsList);
      }
    } catch (error) {
      console.error('Error fetching ticket data:', error);
    }
  };

  return (
    <div>
     {isMobile ? 
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 margintopmobile" align="center">
                <button className={isCreateTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Create Ticket</button>
                <button className={isMyTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>My Tickets</button>                
            </div>
            :
            <se-block color="standard" align="center" height="70px">
                <div className="rows grey-backgroundCreate margintopticket margintoptablet" style={{ textAlign: "center"}}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" align="center">
                        <div>
                            <button className={isCreateTicketActive ? "ActiveButton pointer" : "InactiveButton pointer"} onClick={onCurrentStatus}>Create Ticket</button>
                            <button className={isMyTicketActive ? "histortActiveButton pointer" : "historyInactiveButton pointer"} onClick={onHistoryStatus}>My Tickets</button>
                        </div>
                    </div>
                </div>
            </se-block>}
    <form onSubmit={handleSubmit}>
      {isCreateTicketActive && (
        <div id="ticketForm" className="margintopticket margintopmobile" style={{ textAlign: "center" }}>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <se-form-field id="select-form" label="Site Name :" required="true" padding="none" type="select" option="inline">
              <select id="site-dropdown" onChange={handleSiteChange} value={selectedSite}>
                <option value="">Select a Site</option>
                {siteList.map(site => <option key={site.customerId} value={site.location}>{site.location}</option>)}
              </select>
            </se-form-field>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 margintopticket">
            <se-form-field id="select-form" label="Gateway ID :" required="true" padding="none" type="select" option="inline">
              <select id="gateway-dropdown" onChange={handleGatewayChange} value={selectedGateway}>
                {selectedSite ? (
                  <>
                    <option value="">Select a Gateway ID</option>
                    {gatewayList.map(gateway => (
                      <option key={gateway.customerId} value={gateway.customerId}>
                        {gateway.GatewayId} - {gateway.SiteName || 'Unknown Site'}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">Select a Site First</option>
                )}
              </select>
            </se-form-field>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 margintopticket">
            <se-form-field label="Customer Message :" required="true">
              <div>
                <textarea
                    id="customer-message"
                    onChange={handleCustomerMessageChange}
                    value={customerMessage}
                    placeholder="Enter text here"/>                               
              </div>
              <div>
                {customerMessage.length===500 && (<div style={{ color: 'red' }}>
                  Customer message should not exceed 500 characters</div>)}
              </div>                           
            </se-form-field>                                         
          </div>
          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 submit margintopmobile">
            <button type="submit" className={isFormValid ? "ActiveButton pointer" : "InactiveButton pointer"} disabled={!isFormValid}>Submit</button>                  
          </div>
        </div>
      )}
      </form>
            {isMyTicketActive && (
                <div id="myticketForm" className="ticket Scroll">
                    <se-block display="block" className="px-0">
                        <TableComponent sortable={true} tableHeader={['Ticket ID', 'Site Name', 'Gateway ID', 'Customer Message', ' Status ', 'Raised Date', 'Closed Date']} tableData={userticketsData} tableName='UserTicketsTable' />
                    </se-block>
                </div>
            )}
        <div class="row justify-content-md-center">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <se-snackbar id="snackbar_success" duration="5000" type={successType} icon="" message={successMsg} ></se-snackbar>
            </div>
        </div>
    </div>
  );
};
export default withCommonLandscape(Usertickets);