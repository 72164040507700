import { UserManager} from "oidc-client";
let logoutTimer;

const startLogoutTimer = () => {
  logoutTimer = setTimeout(() => {
    window.location.href = process.env.REACT_APP_LOGOFF_REDIRECT_URL;
    sessionStorage.clear();
    this.UserManager.clearStaleState();
  }, 30 * 60 * 1000); 
};

const resetLogoutTimer = () => {
  clearTimeout(logoutTimer);
  startLogoutTimer();
};

const setupIdleTimeout = () => {
  document.addEventListener('mousemove', resetLogoutTimer);
  document.addEventListener('mousedown', resetLogoutTimer);
  document.addEventListener('keypress', resetLogoutTimer);
  document.addEventListener('touchstart', resetLogoutTimer);
  startLogoutTimer();
};

export default setupIdleTimeout;